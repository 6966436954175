/* eslint-disable @typescript-eslint/no-explicit-any */
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Descendant } from 'slate'
import { useDebounce, useUpdateEffect } from 'usehooks-ts'

import { EMPTY_EDITOR_VALUE } from '@/constants/slate'
import {
  uploadSource,
  useCreateInterview,
  useGetInterviewUploadUrl
} from '@/features/interview'
import {
  Project,
  UpdateProjectDTO,
  useProject,
  useUpdateProject
} from '@/features/project'
import { useSlateEditor } from '@/hooks'
import { queryClient } from '@/lib/react-query'
import { ITabItem } from '@/types'
import { normalizeProjectDto, serializeSlateValue } from '@/utils'

export const useProjectIndexPage = () => {
  const { projectId } = useParams()

  const [activeTab, setActiveTab] = useState<ITabItem>({
    label: 'Research questions',
    slug: 'tab-research-questions'
  })

  const [refetchInterval, setRefetchInterval] = useState<number | false>(false)

  const [projectData, setProjectData] = useState<Project>({
    questionsContent: EMPTY_EDITOR_VALUE,
    interviews: []
  })

  const { data: project, isLoading: isProjectLoading } = useProject({
    projectId: parseInt(projectId as string),
    refetchInterval: refetchInterval
  })

  const { mutateAsync: updateProject, isLoading: isProjectUpdating } =
    useUpdateProject()

  const { mutateAsync: createInterview, isLoading: isInterviewCreating } =
    useCreateInterview()

  const {
    mutateAsync: getInterviewUploadUrl,
    isLoading: isGettingInterviewUploadUrl
  } = useGetInterviewUploadUrl()

  const [isUploadingInterview, setIsUploadingInterview] = useState(false)

  const debouncedData = useDebounce(projectData, 1000)

  const { editor, renderElement, renderLeaf } = useSlateEditor()

  const tabs: ITabItem[] = useMemo(() => {
    return [
      {
        label: 'Research questions',
        slug: 'tab-research-questions'
      },
      {
        label: 'Interviews',
        slug: 'tab-interviews',
        badge: projectData?.interviews?.length.toString() ?? '0'
      }
    ]
  }, [projectData])

  const onTabChange = useCallback(
    (newTab: ITabItem) => {
      setActiveTab(newTab)
      if (newTab.slug === 'tab-interviews') {
        enablePolling()
      } else if (newTab.slug === 'tab-research-questions') {
        disablePolling()
      }
    },
    [setActiveTab]
  )

  const onChangeTitle = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setProjectData({
      ...projectData,
      title: event.target.value as string
    })
  }

  const onChangeDescription = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setProjectData({
      ...projectData,
      description: event.target.value as string
    })
  }

  const onQuestionContentChange = (value: Descendant[]) => {
    const plainValue = serializeSlateValue(value)
    setProjectData({
      ...projectData,
      questions: plainValue,
      questionsContent: value
    })
  }

  const saveChanges = useCallback(
    async (data: Project) => {
      const request: UpdateProjectDTO = {
        id: data.id as number,
        title: data.title,
        description: data.description,
        questions: data.questions,
        questionsContent: JSON.stringify(data.questionsContent)
      }

      await updateProject(request)
    },
    [projectId, updateProject]
  )

  const onClickUploadInterview = useCallback(
    async (selectedFile: File) => {
      try {
        const interviewTab = tabs.find(tab => tab.slug === 'tab-interviews')
        if (interviewTab) {
          setActiveTab(interviewTab)
        }

        setIsUploadingInterview(true)

        // Get upload URL
        const { uploadUrl, source } = await getInterviewUploadUrl({
          projectId: parseInt(projectId as string),
          fileName: selectedFile.name
        })

        await uploadSource(uploadUrl, selectedFile)

        // Create a new interview
        await createInterview({
          projectId: parseInt(projectId as string),
          source,
          sourceFormat: selectedFile.type
        })
      } catch (error) {
        // TODO: show status on UI
        console.error('Uploading interview failed: ', error)
      } finally {
        setIsUploadingInterview(false)
      }
    },
    [projectId, tabs, setActiveTab, createInterview, getInterviewUploadUrl]
  )

  const disablePolling = useCallback(() => {
    queryClient.cancelQueries(['project', projectId])
    setRefetchInterval(false)
  }, [projectId, setRefetchInterval])

  const enablePolling = useCallback(() => {
    setRefetchInterval(10000)
  }, [setRefetchInterval])

  useUpdateEffect(() => {
    if (debouncedData?.title) {
      saveChanges(debouncedData)
    }
  }, [debouncedData, saveChanges])

  useUpdateEffect(() => {
    const clickedSave = (e: KeyboardEvent) => {
      const charCode = String.fromCharCode(e.which).toLowerCase()

      if ((e.ctrlKey || e.metaKey) && charCode === 's') {
        e.preventDefault()
        saveChanges(projectData)
      }
    }

    window.addEventListener('keydown', clickedSave)

    return () => window.removeEventListener('keydown', clickedSave)
  }, [projectData, saveChanges])

  useEffect(() => {
    if (project) {
      const newProjectData = normalizeProjectDto(project)
      setProjectData(newProjectData)
      // IMPORTANT: The next line updates editor's content
      editor.children = newProjectData.questionsContent
    }
  }, [editor, project, setProjectData])

  return {
    projectData,
    isProjectLoading,
    isProjectUpdating,
    isInterviewCreating,
    isGettingInterviewUploadUrl,
    isUploadingInterview,
    activeTab,
    tabs,
    setProjectData,
    onChangeTitle,
    onChangeDescription,
    onTabChange,
    disablePolling,
    enablePolling,
    // Slate
    editor,
    renderElement,
    renderLeaf,
    onQuestionContentChange,
    onClickUploadInterview
  }
}
