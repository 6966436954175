import { forwardRef, Ref } from 'react'

import { OrNull } from '@/types'

export const SlateList = forwardRef(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (props: any, ref: Ref<OrNull<HTMLHeadingElement>>) => {
    return <li {...props} ref={ref} />
  }
)

SlateList.displayName = 'SlateList'

export default SlateList
