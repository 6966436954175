import { useSlate } from 'slate-react'

import { isLinkActive, SlateButton, SlateIcon, unwrapLink } from '@/components'

export const SlateRemoveLinkButton = () => {
  const editor = useSlate()

  const handleMouseDown = () => {
    if (isLinkActive(editor)) {
      unwrapLink(editor)
    }
  }

  return (
    <SlateButton active={isLinkActive(editor)} onMouseDown={handleMouseDown}>
      <SlateIcon>link_off</SlateIcon>
    </SlateButton>
  )
}

SlateRemoveLinkButton.displayName = 'SlateRemoveLinkButton'

export default SlateRemoveLinkButton
