/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMutation } from 'react-query'

import { APIs } from '@/configs/api'
import { axios } from '@/lib/axios'
import { MutationConfig } from '@/lib/react-query'

export type GetInterviewUploadUrlRequestDto = {
  projectId: number
  fileName: string
}

export type GetInterviewUploadUrlResponseDto = {
  uploadUrl: string
  source: string
}

export const getInterviewUploadUrl = (
  request: GetInterviewUploadUrlRequestDto
): Promise<GetInterviewUploadUrlResponseDto> =>
  axios.post(APIs.INTERVIEW.GET_UPLOAD_URL, request, { withCredentials: true })

type UseGetInterviewUploadUrlOptions = {
  config?: MutationConfig<typeof getInterviewUploadUrl>
}

export const useGetInterviewUploadUrl = ({
  config
}: UseGetInterviewUploadUrlOptions = {}) => {
  return useMutation({
    ...config,
    mutationFn: getInterviewUploadUrl
  })
}
