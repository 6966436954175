/* eslint-disable @typescript-eslint/no-explicit-any */
import { useSlate } from 'slate-react'

import { isMarkActive, SlateButton, SlateIcon, toggleMark } from '@/components'

export interface ISlateMarkButtonProps {
  format: string
  icon: string
}

export const SlateMarkButton = ({ format, icon }: ISlateMarkButtonProps) => {
  const editor = useSlate()

  const handleMouseDown = (event: any) => {
    event.preventDefault()
    toggleMark(editor, format)
  }

  return (
    <SlateButton
      active={isMarkActive(editor, format)}
      onMouseDown={handleMouseDown}
    >
      <SlateIcon>{icon}</SlateIcon>
    </SlateButton>
  )
}

SlateMarkButton.displayName = 'SlateMarkButton'

export default SlateMarkButton
