import { useQuery } from 'react-query'

import { APIs } from '@/configs/api'
import { InterviewStatusEnum } from '@/features/interview'
import { axios } from '@/lib/axios'
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query'

export type InterviewDTO = {
  id: number
  title?: string
  description?: string
  status: InterviewStatusEnum
  source?: string
  sourceFormat?: string
  transcript?: string
  transcriptUrl?: string
  transcriptMatchedQuestionsUrl?: string
  transcriptSentimentUrl?: string
  project?: {
    id: number
    title: string
    description: string
  }
}

export const getInterview = (interviewId: number): Promise<InterviewDTO> => {
  return axios.get(`${APIs.INTERVIEW.GET}/${interviewId}`, {
    withCredentials: true
  })
}

type QueryFnType = typeof getInterview

type UseInterviewOptions = {
  interviewId: number
  config?: QueryConfig<QueryFnType>
}

export const useInterview = ({ interviewId, config }: UseInterviewOptions) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: ['interview', interviewId],
    queryFn: () => getInterview(interviewId)
  })
}
