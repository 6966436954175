/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useMemo } from 'react'
import { createEditor } from 'slate'
import { withHistory } from 'slate-history'
import { withReact } from 'slate-react'

import { SlateElement, SlateLeaf, withInlines } from '@/components'

export const useSlateEditor = () => {
  const editor = useMemo(
    () => withInlines(withHistory(withReact(createEditor()))),
    []
  )

  const renderElement = useCallback(
    (props: any) => <SlateElement {...props} />,
    []
  )
  const renderLeaf = useCallback((props: any) => <SlateLeaf {...props} />, [])

  return {
    editor,
    renderElement,
    renderLeaf
  }
}
