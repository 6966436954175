import { useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { AppIcon } from '@/components'
import { PATHS } from '@/configs/misc'
import { AuthLayout, ResetPasswordForm } from '@/features/auth'

export const ResetPasswordPage = () => {
  const navigate = useNavigate()
  const [show, setShow] = useState(true)

  const handleOnSuccess = useCallback(() => {
    setShow(false)
    setTimeout(() => {
      navigate(PATHS.AUTH.LOGIN)
    }, 3000)
  }, [])

  return (
    <AuthLayout>
      <div className='flex flex-col items-center justify-center sm:mx-auto sm:w-full sm:max-w-md'>
        <AppIcon width={40} height={40} />

        <h2 className='mt-6 text-center font-inter text-3xl font-bold text-gray-900'>
          Reset your password
        </h2>
        {show && (
          <p className='mt-2 text-center text-sm font-normal text-gray-900'>
            Remembered your password?
            <a
              href={PATHS.AUTH.LOGIN}
              className='pl-1 font-medium text-indigo-600 hover:text-indigo-500'
            >
              Login
            </a>
          </p>
        )}
      </div>

      <ResetPasswordForm onSuccess={handleOnSuccess} />
    </AuthLayout>
  )
}

ResetPasswordPage.displayName = 'ResetPasswordPage'

export default ResetPasswordPage
