/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC, ReactNode } from 'react'

import { useSlateTranscriptQa } from '@/hooks'

export interface ISlateTranscriptQaProps {
  element: any
  attributes?: any
  children: ReactNode
}

export const SlateTranscriptQa: FC<ISlateTranscriptQaProps> = ({
  element,
  children,
  attributes
}: ISlateTranscriptQaProps) => {
  const { question, answers } = useSlateTranscriptQa(element)

  return (
    <div {...attributes}>
      {children}
      <div contentEditable={false} className='font-medium'>
        <div className='w-fit rounded-lg bg-[#C4B5FD] px-2 text-white'>
          Question answer
        </div>
        <div className='pt-2 italic text-gray-500'>{question}</div>
        <div className='pt-4'>
          {answers?.map((answer: string) => (
            <div key={answer}>&lsquo;{answer}&rsquo;</div>
          ))}
        </div>
      </div>
    </div>
  )
}

SlateTranscriptQa.displayName = 'SlateTranscriptQa'

export default SlateTranscriptQa
