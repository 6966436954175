import classNames from 'classnames'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'

import { Button, FormField, FormFieldError } from '@/components'
import { useForgotPassword } from '@/hooks'

const validationSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Email is required.')
})

export const ForgotPasswordForm = () => {
  const { initialValues, handleSubmit, formResponse } = useForgotPassword()

  return (
    <div className='mt-8 sm:mx-auto sm:w-full sm:max-w-md'>
      <div className='bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10'>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting }) => (
            <Form noValidate className='space-y-6'>
              <div>
                <FormField name='email' type='email' label='Email address' />
                <FormFieldError name='email' />
              </div>

              <div>
                <Button
                  type='submit'
                  isLoading={isSubmitting}
                  disabled={isSubmitting}
                  className='w-full'
                >
                  Send
                </Button>

                {formResponse?.message && (
                  <div
                    className={classNames('mt-2 text-sm', {
                      'text-red-600': !formResponse.isSuccess,
                      'text-green-600': formResponse.isSuccess
                    })}
                  >
                    {formResponse?.message}
                  </div>
                )}
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  )
}

ForgotPasswordForm.displayName = 'ForgotPasswordForm'

export default ForgotPasswordForm
