/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMutation } from 'react-query'

import { APIs } from '@/configs/api'
import { axios } from '@/lib/axios'
import { MutationConfig, queryClient } from '@/lib/react-query'

export type DeleteProjectDTO = {
  projectId: string
}

export const deleteProject = (data: DeleteProjectDTO) =>
  axios.patch(APIs.PROJECT.DELETE, data, { withCredentials: true })

type UseDeleteProjectOptions = {
  config?: MutationConfig<typeof deleteProject>
}

export const useDeleteProject = ({ config }: UseDeleteProjectOptions = {}) => {
  return useMutation({
    onError: (_, __, context: any) => {
      if (context?.previousProjects) {
        queryClient.setQueryData('projects', context.previousProjects)
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries('projects')
    },
    ...config,
    mutationFn: deleteProject
  })
}
