/* eslint-disable @typescript-eslint/no-explicit-any */
import { BaseEditor } from 'slate'
import { HistoryEditor } from 'slate-history'
import { ReactEditor, useSlate } from 'slate-react'

import { isLinkActive, SlateButton, SlateIcon, wrapLink } from '@/components'

export const SlateAddLinkButton = () => {
  const editor = useSlate()

  const insertLink = (
    editor: BaseEditor & ReactEditor & HistoryEditor,
    url: string
  ) => {
    if (editor.selection) {
      wrapLink(editor, url)
    }
  }

  const handleMouseDown = (event: any) => {
    event.preventDefault()
    const url = window.prompt('Enter the URL of the link:')
    if (!url) return
    insertLink(editor, url)
  }

  return (
    <SlateButton active={isLinkActive(editor)} onMouseDown={handleMouseDown}>
      <SlateIcon>link</SlateIcon>
    </SlateButton>
  )
}

SlateAddLinkButton.displayName = 'SlateAddLinkButton'

export default SlateAddLinkButton
