/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMutation } from 'react-query'

import { APIs } from '@/configs/api'
import { Interview } from '@/features/interview'
import { axios } from '@/lib/axios'
import { MutationConfig, queryClient } from '@/lib/react-query'

export type UnpakInterviewDTO = {
  interviewId: number
}

export const unpakInterview = (data: UnpakInterviewDTO): Promise<Interview> =>
  axios.post(APIs.INTERVIEW.UNPAK, data, { withCredentials: true })

type UseUnpakInterviewOptions = {
  interviewId: number
  config?: MutationConfig<typeof unpakInterview>
}

export const useUnpakInterview = ({
  interviewId,
  config
}: UseUnpakInterviewOptions) => {
  return useMutation({
    onError: (_, __, context: any) => {
      if (context?.previousInterviews) {
        queryClient.setQueryData('interviews', context.previousInterviews)
      }
    },
    onSuccess: (newInterview: Interview) => {
      queryClient.invalidateQueries('interviews')
      queryClient.invalidateQueries(['interview', interviewId])
      queryClient.invalidateQueries(['project', newInterview.project?.id])
    },
    ...config,
    mutationFn: unpakInterview
  })
}
