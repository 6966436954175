import { Form, Formik } from 'formik'
import { FC, memo } from 'react'
import * as Yup from 'yup'

import {
  Button,
  FormField,
  FormFieldCheckbox,
  FormFieldError
} from '@/components'
import { PATHS } from '@/configs/misc'
import { useLogin } from '@/hooks'

const validationSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Email is required.'),
  password: Yup.string().required('Password is required.'),
  rememberMe: Yup.boolean()
})

type LoginFormProps = {
  onSuccess: () => void
}

export const LoginForm: FC<LoginFormProps> = memo(
  ({ onSuccess }: LoginFormProps) => {
    const { initialValues, handleSubmit, formResponse } = useLogin({
      onSuccess
    })

    return (
      <div className='mt-8 sm:mx-auto sm:w-full sm:max-w-md'>
        <div className='bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10'>
          <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ isSubmitting }) => (
              <Form noValidate className='space-y-6'>
                <div>
                  <FormField name='email' type='email' label='Email address' />
                  <FormFieldError name='email' />
                </div>

                <div>
                  <FormField name='password' type='password' label='Password' />
                  <FormFieldError name='password' />
                </div>

                <div className='flex items-center justify-between'>
                  <FormFieldCheckbox name='rememberMe' label='Remember me' />
                  <FormFieldError name='rememberMe' />

                  <div className='text-sm'>
                    <a
                      href={PATHS.AUTH.FORGOT_PASSWORD}
                      className='font-medium text-indigo-600 hover:text-indigo-500'
                    >
                      Forgot your password?
                    </a>
                  </div>
                </div>

                <div>
                  <Button
                    type='submit'
                    isLoading={isSubmitting}
                    disabled={isSubmitting}
                    className='w-full'
                  >
                    Log In
                  </Button>

                  {formResponse?.message && (
                    <div className='mt-2 text-sm text-red-600'>
                      {formResponse?.message}
                    </div>
                  )}
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    )
  }
)

LoginForm.displayName = 'LoginForm'

export default LoginForm
