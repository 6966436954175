import { FormikHelpers } from 'formik'
import { useState } from 'react'

import { DEFAULT_ERROR_MESSAGE } from '@/configs/message'
import { RegisterFormValues } from '@/features/auth'
import { useAuth } from '@/lib/auth'
import { FormResponse } from '@/types'

const initialValues: RegisterFormValues = {
  firstName: '',
  lastName: '',
  email: '',
  password: '',
  confirmPassword: ''
}

export const useRegister = (
  onSuccess?: (email: string) => void
): {
  initialValues: RegisterFormValues
  handleSubmit: (
    values: RegisterFormValues,
    actions: FormikHelpers<RegisterFormValues>
  ) => void
  formResponse: FormResponse
} => {
  const { register } = useAuth()

  const [formResponse, setFormResponse] = useState<FormResponse>({
    isSuccess: false
  })

  const handleSubmit = async (
    values: RegisterFormValues,
    actions: FormikHelpers<RegisterFormValues>
  ) => {
    actions.setSubmitting(true)
    setFormResponse({
      isSuccess: false
    })

    try {
      await register(values)
      onSuccess?.(values.email)
    } catch (error) {
      const errorMessage = (error as string) ?? DEFAULT_ERROR_MESSAGE
      setFormResponse({
        isSuccess: false,
        message: errorMessage
      })
    } finally {
      actions.setSubmitting(false)
    }
  }

  return { initialValues, handleSubmit, formResponse }
}
