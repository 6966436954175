import classNames from 'classnames'
import { PropsWithChildren, useEffect, useRef } from 'react'

import { BaseProps } from '@/types'
import { clamp } from '@/utils/misc'

export const SlateToolbar = ({
  className,
  ...props
}: PropsWithChildren<BaseProps>) => {
  const isInitial = useRef(true)
  const toolbarRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const downDelay = 80

    const updateStyles = () => {
      if (toolbarRef && toolbarRef?.current) {
        const { top } = toolbarRef.current.getBoundingClientRect()

        const scrollY = clamp(
          window.scrollY,
          0,
          document.body.scrollHeight - window.innerHeight
        )

        if (top < downDelay && scrollY > 0) {
          toolbarRef.current.classList.add('slate-toolbar-floating')
        } else {
          toolbarRef.current.classList.remove('slate-toolbar-floating')
        }
      }

      isInitial.current = false
    }

    updateStyles()
    window.addEventListener('scroll', updateStyles, { passive: true })
    window.addEventListener('resize', updateStyles)

    return () => {
      window.removeEventListener('scroll', updateStyles)
      window.removeEventListener('resize', updateStyles)
    }
  }, [])

  return (
    <div
      {...props}
      ref={toolbarRef}
      className={classNames(
        'slate-menu slate-toolbar sticky top-[70px] p-5 mx-0 my-5 border-gray-300 border-y-2 z-[100] bg-white transition-all',
        className
      )}
    />
  )
}

SlateToolbar.displayName = 'SlateToolbar'

export default SlateToolbar
