import { useMemo } from 'react'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useSlateTranscriptQa = (element: any) => {
  const question = useMemo(() => (element?.question as string) ?? '', [element])

  const answers = useMemo(() => (element?.answer as string[]) ?? [], [element])

  return {
    question,
    answers
  }
}
