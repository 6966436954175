/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMutation } from 'react-query'

import { APIs } from '@/configs/api'
import { Speaker } from '@/features/speaker'
import { axios } from '@/lib/axios'
import { MutationConfig, queryClient } from '@/lib/react-query'

export type UpdateSpeakerDTO = {
  id: number
  title: string
}

export const updateSpeaker = (data: UpdateSpeakerDTO): Promise<Speaker> =>
  axios.patch(APIs.SPEAKER.UPDATE, data, { withCredentials: true })

type UseUpdateSpeakerOptions = {
  config?: MutationConfig<typeof updateSpeaker>
}

export const useUpdateSpeaker = ({ config }: UseUpdateSpeakerOptions = {}) => {
  return useMutation({
    onError: (_, __, context: any) => {
      if (context?.previousSpeakers) {
        queryClient.setQueryData('speakers', context.previousSpeakers)
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries('speakers')
      queryClient.invalidateQueries('transcripts')
    },
    ...config,
    mutationFn: updateSpeaker
  })
}
