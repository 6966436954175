import classNames from 'classnames'
import { ChangeEvent, FC, memo, ReactNode, useCallback, useRef } from 'react'

import { TTheme } from '@/types'

export interface IUploadButtonProps {
  theme?: TTheme
  isLoading?: boolean
  disabled?: boolean
  className?: string
  loaderColorHex?: string
  children?: ReactNode
  callback?: (file: File) => void
}

export const UploadButton: FC<IUploadButtonProps> = memo(
  ({
    theme = 'primary',
    children,
    className = '',
    isLoading = false,
    loaderColorHex = '#fff',
    disabled = false,
    callback
  }: IUploadButtonProps) => {
    const inputFileRef = useRef<HTMLInputElement>(null)

    const onClickUpload = useCallback(() => {
      inputFileRef?.current?.click()
    }, [inputFileRef])

    const onChangeFileSelect = useCallback(
      (event: ChangeEvent<HTMLInputElement>) => {
        const selectedFile = event.currentTarget?.files?.[0]
        if (selectedFile) {
          callback?.(selectedFile)
        }
      },
      [callback]
    )

    return (
      <button
        className={classNames(
          className,
          'relative flex font-inter items-center justify-center py-2 px-4 font-medium rounded-md border border-transparent focus:ring-2  focus:ring-offset-2 shadow-sm focus:outline-none',
          {
            'text-white bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500':
              theme === 'primary',
            'text-white bg-red-600 hover:bg-red-700 focus:ring-red-500':
              theme === 'danger'
          }
        )}
        onClick={onClickUpload}
        disabled={disabled}
      >
        {isLoading && (
          <svg
            width='16'
            height='16'
            className='mr-2 inline animate-spin motion-reduce:animate-none'
            xmlns='http://www.w3.org/2000/svg'
            fill='none'
            viewBox='0 0 24 24'
          >
            <circle
              className='opacity-25'
              cx='12'
              cy='12'
              r='10'
              stroke={loaderColorHex}
              strokeWidth='4'
            />
            <path
              className='opacity-100'
              fill={loaderColorHex}
              d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'
            />
          </svg>
        )}
        {children}
        <input
          type='file'
          accept='audio/*,video/*'
          ref={inputFileRef}
          className='hidden'
          onChange={onChangeFileSelect}
        />
      </button>
    )
  }
)

UploadButton.displayName = 'UploadButton'

export default UploadButton
