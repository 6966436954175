import classNames from 'classnames'
import { forwardRef, Ref } from 'react'
import { useSelected } from 'slate-react'

import { OrNull } from '@/types'

// Put this at the start and end of an inline component to work around this Chromium bug:
// https://bugs.chromium.org/p/chromium/issues/detail?id=1249405
const InlineChromiumBugfix = () => (
  <span className='text-[0px]'>
    ${String.fromCodePoint(160) /* Non-breaking space */}
  </span>
)

export const SlateLink = forwardRef(
  (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    { attributes, children, element }: any,
    ref: Ref<OrNull<HTMLAnchorElement>>
  ) => {
    const selected = useSelected()

    return (
      <a
        {...attributes}
        href={element.url}
        className={classNames({ 'shadow-sm': selected })}
        contentEditable={false}
        ref={ref}
      >
        <InlineChromiumBugfix />
        {children}
        <InlineChromiumBugfix />
      </a>
    )
  }
)

SlateLink.displayName = 'SlateLink'

export default SlateLink
