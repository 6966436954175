import { Descendant } from 'slate'

export const EMPTY_EDITOR_VALUE: Descendant[] = [
  {
    type: 'component.paragraph',
    children: [{ text: '' }]
  }
]

export const EMPTY_COMMENT_EDITOR_VALUE1 = [
  {
    type: 'component.transcript.qa',
    question:
      'In the last week, how many qualitative interviews have you done?',
    answer: ['none', '2 guys managing sites'],
    children: [{ text: '' }]
  },
  { type: 'component.paragraph', children: [{ text: '' }] }
]

export const EMPTY_COMMENT_EDITOR_VALUE2 = [
  {
    type: 'component.transcript.qa',
    question: 'Do you use any applications?',
    answer: ['Pendo feedback', 'product road'],
    children: [{ text: '' }]
  },
  { type: 'component.paragraph', children: [{ text: '' }] }
]
