import { LockOpenIcon, RefreshIcon } from '@heroicons/react/solid'
import classNames from 'classnames'
import { FC, memo } from 'react'
import ReactTextareaAutosize from 'react-textarea-autosize'

import cover from '@/assets/images/cover.png'
import { Spinner } from '@/components'
import { Interview } from '@/features/interview'
import { useInterviewCard } from '@/hooks'

export interface IInterviewCard {
  interview: Interview
  enablePolling: () => void
  disablePolling: () => void
}

export const InterviewCard: FC<IInterviewCard> = memo(
  ({ interview, enablePolling, disablePolling }: IInterviewCard) => {
    const {
      interviewData,
      isUnpakd,
      isProcessing,
      onClickInterviewCard,
      onClickRepakInterview,
      onChangeInterviewTitle,
      onChangeInterviewDescription
    } = useInterviewCard(interview, enablePolling, disablePolling)

    return (
      <div>
        <div className='group aspect-w-10 aspect-h-7 block w-full rounded-lg bg-gray-100 ring-offset-gray-100 focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2'>
          {interviewData.source &&
            interviewData.sourceFormat?.startsWith('video') && (
              <video
                src={`${interviewData.source}?t=0.3`}
                className='rounded-lg object-cover'
                autoPlay
                muted
              />
            )}

          {(!interviewData.source ||
            interviewData.sourceFormat?.startsWith('audio')) && (
            <img
              src={cover}
              alt={`${interviewData.title}`}
              className={classNames(
                'pointer-events-none rounded-lg border-none border-transparent object-cover group-hover:blur-none',
                {
                  'blur-[1px]': !isUnpakd
                }
              )}
            />
          )}

          <div className='absolute inset-0 flex items-center justify-center rounded-lg opacity-80 focus:outline-none group-hover:opacity-100'>
            <span className='isolate inline-flex rounded-md shadow-sm'>
              <button
                type='button'
                className={classNames(
                  'relative inline-flex items-center border border-indigo-300 bg-indigo-200 px-3 py-2 text-sm font-medium text-indigo-700 hover:bg-indigo-300 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500',
                  { 'rounded-l-md': isUnpakd, 'rounded-md': !isUnpakd }
                )}
                onClick={onClickInterviewCard}
                disabled={isProcessing}
              >
                {isProcessing ? (
                  <>
                    <Spinner
                      className='mr-2 h-5 w-5 text-indigo-500'
                      aria-hidden='true'
                    />
                    Unpaking the interview
                  </>
                ) : (
                  <>
                    {isUnpakd ? (
                      `View the interview`
                    ) : (
                      <>
                        <LockOpenIcon
                          className='mr-2 h-5 w-5 text-indigo-500'
                          aria-hidden='true'
                        />
                        Unpakd the interview
                      </>
                    )}
                  </>
                )}
              </button>
              {isUnpakd && !isProcessing && (
                <button
                  type='button'
                  className='relative -ml-px inline-flex items-center rounded-r-md border border-indigo-300 bg-indigo-200 p-2 text-sm font-medium text-indigo-700 hover:bg-indigo-300 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500'
                  onClick={onClickRepakInterview}
                  disabled={isProcessing}
                >
                  <RefreshIcon
                    className='h-5 w-5 text-indigo-500'
                    aria-hidden='true'
                  />
                </button>
              )}
            </span>
          </div>
        </div>

        <input
          className='mt-2 block w-full resize-none truncate border-none p-0 text-sm font-medium text-gray-500 focus:outline-none focus:ring-0'
          value={interviewData.title}
          onChange={onChangeInterviewTitle}
          disabled={isProcessing}
        />
        <ReactTextareaAutosize
          className='mt-2 block w-full resize-none border-none p-0 text-sm font-medium text-gray-500 focus:outline-none focus:ring-0'
          maxRows={2}
          value={interviewData.description}
          onChange={onChangeInterviewDescription}
          disabled={isProcessing}
        />
      </div>
    )
  }
)

InterviewCard.displayName = 'InterviewCard'

export default InterviewCard
