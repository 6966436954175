/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMutation } from 'react-query'

import { APIs } from '@/configs/api'
import { Interview } from '@/features/interview'
import { axios } from '@/lib/axios'
import { MutationConfig, queryClient } from '@/lib/react-query'

export type UpdateInterviewDTO = {
  id: number
  title?: string
  description?: string
  transcriptContent?: string
}

export const updateInterview = (data: UpdateInterviewDTO): Promise<Interview> =>
  axios.patch(APIs.INTERVIEW.UPDATE, data, { withCredentials: true })

type UseUpdateInterviewOptions = {
  config?: MutationConfig<typeof updateInterview>
}

export const useUpdateInterview = ({
  config
}: UseUpdateInterviewOptions = {}) => {
  return useMutation({
    onError: (_, __, context: any) => {
      if (context?.previousInterviews) {
        queryClient.setQueryData('interviews', context.previousInterviews)
      }
    },
    onSuccess: (newInterview: Interview) => {
      queryClient.invalidateQueries('interviews')
      if (newInterview.project?.id) {
        queryClient.invalidateQueries(['project', newInterview.project.id])
      }
    },
    ...config,
    mutationFn: updateInterview
  })
}
