import { API_URL } from './env'

export const APIs = {
  AUTH: {
    ME: `${API_URL}/api/auth/me`,
    LOGIN: `${API_URL}/api/auth/login`,
    LOGOUT: `${API_URL}/api/auth/logout`,
    REGISTER: `${API_URL}/api/auth/register`,
    FORGOT_PASSWORD: `${API_URL}/api/auth/forgot-password`,
    RESET_PASSWORD: `${API_URL}/api/auth/reset-password`,
    VERIFY_EMAIL: `${API_URL}/api/auth/verify-email`,
    REFRESH_TOKEN: `${API_URL}/api/auth/refresh-token`
  },
  PROJECT: {
    CREATE: `${API_URL}/api/project`,
    GET: `${API_URL}/api/project`,
    GET_LIST: `${API_URL}/api/project/list`,
    UPDATE: `${API_URL}/api/project`,
    DELETE: `${API_URL}/api/project`
  },
  INTERVIEW: {
    CREATE: `${API_URL}/api/interview`,
    DELETE: `${API_URL}/api/interview`,
    GET: `${API_URL}/api/interview`,
    GET_LIST: `${API_URL}/api/interview/list`,
    GET_UPLOAD_URL: `${API_URL}/api/interview/upload-url`,
    UPDATE: `${API_URL}/api/interview`,
    UNPAK: `${API_URL}/api/interview/unpak`
  },
  TRANSCRIPT: {
    GET_LIST: `${API_URL}/api/transcript/list`,
    UPDATE: `${API_URL}/api/transcript`
  },
  SPEAKER: {
    UPDATE: `${API_URL}/api/speaker`
  }
}
