import { FC, memo } from 'react'
import { Editable, Slate } from 'slate-react'

import { MoodIcon } from '@/components'
import { Transcript } from '@/features/transcript'
import { useInterviewTranscript } from '@/hooks'

export interface IInterviewTranscriptProps {
  measureRef?: (element: Element | null) => void
  transcript: Transcript
}

export const InterviewTranscript: FC<IInterviewTranscriptProps> = memo(
  ({ measureRef, transcript }: IInterviewTranscriptProps) => {
    const {
      transcriptData,
      transcriptContent,
      timestamp,
      commentContent,
      transcriptEditor,
      commentEditor,
      transcriptRenderElement,
      transcriptRenderLeaf,
      onTranscriptEditorKeyDown,
      onTranscriptChange,
      commentRenderElement,
      commentRenderLeaf,
      onCommentEditorKeyDown,
      onCommentChange,
      onChangeSpeakerTitle
    } = useInterviewTranscript(transcript)

    return (
      <div className='p-4' ref={measureRef}>
        <div className='flex flex-row'>
          <span className='min-w-[1.5rem] rounded-lg bg-[#C4B5FD] text-center text-white'>
            {parseInt(transcript.speaker.interviewSpeakerId) + 1}
          </span>
          <input
            className='ml-4 inline max-w-[10rem] resize-none truncate border-none font-medium text-gray-500 focus:outline-none focus:ring-0'
            value={transcriptData.speaker.title}
            onChange={onChangeSpeakerTitle}
            maxLength={32}
          />
          <span className='ml-4 font-medium italic text-gray-500'>
            Timestamp {timestamp}
          </span>
          {transcript.sentiment && (
            <span className='ml-4 flex items-center font-medium italic text-gray-500'>
              Mood:{' '}
              <MoodIcon mood={transcript.sentiment.label} className='ml-2' />
            </span>
          )}
        </div>
        <div className='grid w-full gap-x-4 lg:grid-cols-3 lg:gap-x-8'>
          <div className='col-span-2 py-4'>
            <Slate
              editor={transcriptEditor}
              value={transcriptContent}
              onChange={onTranscriptChange}
            >
              <Editable
                renderElement={transcriptRenderElement}
                renderLeaf={transcriptRenderLeaf}
                spellCheck
                onKeyDown={onTranscriptEditorKeyDown}
              />
            </Slate>
          </div>
          <div className='col-span-1 py-4'>
            <Slate
              editor={commentEditor}
              value={commentContent}
              onChange={onCommentChange}
            >
              <Editable
                renderElement={commentRenderElement}
                renderLeaf={commentRenderLeaf}
                spellCheck
                onKeyDown={onCommentEditorKeyDown}
              />
            </Slate>
          </div>
        </div>
      </div>
    )
  }
)

InterviewTranscript.displayName = 'InterviewTranscript'

export default InterviewTranscript
