import { forwardRef, Ref } from 'react'

import { OrNull } from '@/types'

export const SlateTitle = forwardRef(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (props: any, ref: Ref<OrNull<HTMLHeadingElement>>) => {
    return <h1 {...props} ref={ref} />
  }
)

SlateTitle.displayName = 'SlateTitle'

export default SlateTitle
