/* eslint-disable @typescript-eslint/no-explicit-any */
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Descendant } from 'slate'
import { useDebounce, useUpdateEffect } from 'usehooks-ts'

import {
  Interview,
  InterviewStatusEnum,
  useInterview,
  useUpdateInterview
} from '@/features/interview'
import { useSlateEditor } from '@/hooks'
import { ITabItem } from '@/types'

export const useInterviewDetailPage = () => {
  const { interviewId } = useParams()

  const { data: interview, isLoading: isInterviewLoading } = useInterview({
    interviewId: parseInt(interviewId as string)
  })

  const { mutateAsync: updateInterview, isLoading: isInterviewUpdating } =
    useUpdateInterview()

  const [activeTab, setActiveTab] = useState<ITabItem>({
    label: 'Transcript',
    slug: 'tab-transcript'
  })

  const [interviewData, setInterviewData] = useState<Interview>({
    status: InterviewStatusEnum.Initial
  })

  const debouncedData = useDebounce(interviewData, 1000)

  const { editor, renderElement, renderLeaf } = useSlateEditor()

  const tabs: ITabItem[] = useMemo(() => {
    return [
      {
        label: 'Transcript',
        slug: 'tab-transcript'
      }
    ]
  }, [])

  const saveChanges = useCallback(
    async (data: Interview) => {
      await updateInterview({
        ...data,
        id: data.id as number
      })
    },
    [interviewId, updateInterview]
  )

  const onTabChange = useCallback(
    (newTab: ITabItem) => {
      setActiveTab(newTab)
    },
    [setActiveTab]
  )

  const onChangeTitle = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setInterviewData({
      ...interviewData,
      title: event.target.value as string
    })
  }

  const onChangeDescription = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setInterviewData({
      ...interviewData,
      description: event.target.value as string
    })
  }

  const onTranscriptContentChange = (value: Descendant[]) => {
    setInterviewData({
      ...interviewData,
      transcript: JSON.stringify(value)
    })
  }

  useEffect(() => {
    if (interview) {
      const newInterviewData: Interview = {
        id: interview.id,
        title: interview.title,
        description: interview.description,
        status: interview.status
      }
      setInterviewData(newInterviewData)
    }
  }, [interview, setInterviewData])

  useUpdateEffect(() => {
    if (debouncedData.title) {
      saveChanges(debouncedData)
    }
  }, [debouncedData, saveChanges])

  useUpdateEffect(() => {
    const clickedSave = (e: KeyboardEvent) => {
      const charCode = String.fromCharCode(e.which).toLowerCase()

      if ((e.ctrlKey || e.metaKey) && charCode === 's') {
        e.preventDefault()
        saveChanges(interviewData)
      }
    }

    window.addEventListener('keydown', clickedSave)

    return () => window.removeEventListener('keydown', clickedSave)
  }, [interviewData, saveChanges])

  return {
    isInterviewLoading,
    isInterviewUpdating,
    interviewData,
    activeTab,
    tabs,
    setInterviewData,
    onChangeTitle,
    onChangeDescription,
    onTabChange,
    // Slate
    editor,
    renderElement,
    renderLeaf,
    onTranscriptContentChange
  }
}
