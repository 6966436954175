import { Navigate, Route, Routes } from 'react-router-dom'

import {
  ForgotPasswordPage,
  LoginPage,
  RegisterPage,
  ResetPasswordPage
} from '@/features/auth'

export const AuthRoutes = () => {
  return (
    <Routes>
      <Route path='register' element={<RegisterPage />} />
      <Route path='login' element={<LoginPage />} />
      <Route path='forgot-password' element={<ForgotPasswordPage />} />
      <Route path='reset-password' element={<ResetPasswordPage />} />
      <Route path='*' element={<Navigate to='login' />} />
    </Routes>
  )
}
