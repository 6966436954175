import classNames from 'classnames'
import { forwardRef, PropsWithChildren, Ref } from 'react'

import { BaseProps } from '@/types'

export const SlateMenu = forwardRef(
  (
    { className, ...props }: PropsWithChildren<BaseProps>,
    ref: Ref<HTMLDivElement>
  ) => (
    <div {...props} ref={ref} className={classNames('slate-menu', className)} />
  )
)

SlateMenu.displayName = 'SlateMenu'

export default SlateMenu
