export const META_DEFAULTS = {
  logo: '/favicon.ico',
  title: 'Unpakd',
  description: 'A home for insights'
}

export const PATHS = {
  BASE: '/',
  AUTH: {
    LOGIN: '/auth/login',
    LOGOUT: '/auth/logout',
    REGISTER: '/auth/register',
    FORGOT_PASSWORD: '/auth/forgot-password'
  },
  PROJECT: {
    BASE: '/project'
  },
  INTERVIEW: {
    BASE: '/interview'
  }
}
