import { InterviewStatusEnum } from '@/features/interview'

export const isInterviewUnpakd = (status: InterviewStatusEnum): boolean =>
  status === InterviewStatusEnum.TranscriptGenerationCompleted

export const isInterviewProcessing = (status: InterviewStatusEnum): boolean =>
  [
    InterviewStatusEnum.TranscriptInProgress,
    InterviewStatusEnum.TranscriptCompleted,
    InterviewStatusEnum.MatchQuestionsInProgress,
    InterviewStatusEnum.MatchQuestionsCompleted,
    InterviewStatusEnum.SentimentInProgress,
    InterviewStatusEnum.SentimentCompleted,
    InterviewStatusEnum.ExtractAnswerInProgress,
    InterviewStatusEnum.ExtractAnswerCompleted,
    InterviewStatusEnum.TranscriptGenerationInProgress
  ].includes(status)
