import { APIs } from '@/configs/api'
import { User } from '@/features/auth'
import { axios } from '@/lib/axios'

export type LoginCredentialsDTO = {
  email: string
  password: string
}

export const loginWithEmailAndPassword = (
  data: LoginCredentialsDTO
): Promise<User> => axios.post(APIs.AUTH.LOGIN, data, { withCredentials: true })
