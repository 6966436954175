import { FC, memo, useMemo } from 'react'
import { Helmet } from 'react-helmet-async'

import { META_DEFAULTS } from '@/configs/misc'

export interface IAppHeadProps {
  title?: string
  description?: string
}

export const AppHead: FC<IAppHeadProps> = memo(
  ({ title, description }: IAppHeadProps) => {
    const logo = useMemo(() => META_DEFAULTS.logo, [])

    const pageTitle = useMemo(
      () =>
        title && description
          ? `${title} | ${description}`
          : `${META_DEFAULTS.title} | ${META_DEFAULTS.description}`,
      [title, description]
    )

    const pageDescription = useMemo(
      () => description ?? META_DEFAULTS.description,
      [description]
    )

    return (
      <Helmet title={pageTitle} defaultTitle={META_DEFAULTS.title}>
        <link rel='icon' href={logo} />
        <link rel='shortcut icon' type='image/x-icon' href={logo} />
        <link rel='apple-touch-icon' sizes='180x180' href={logo} />
        <meta name='theme-color' content='#7b46f6' />
        <meta charSet='utf-8' />
        <meta name='viewport' content='width=device-width, initial-scale=1' />
        <meta name='description' content={pageDescription} />

        <meta name='application-name' content={META_DEFAULTS.title} />
        <meta name='apple-mobile-web-app-capable' content='yes' />
        <meta name='apple-mobile-web-app-status-bar-style' content='default' />
        <meta name='apple-mobile-web-app-title' content={META_DEFAULTS.title} />
        <meta name='format-detection' content='telephone=no' />
        <meta name='mobile-web-app-capable' content='yes' />

        <meta property='og:title' content={pageTitle} />
        <meta property='og:description' content={pageDescription} />
        <meta property='og:image' content={logo} />
        <meta property='og:type' content='website' />

        <link
          rel='stylesheet'
          href='https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@48,400,0,0&display=swap'
        />
      </Helmet>
    )
  }
)

AppHead.displayName = 'AppHead'

export default AppHead
