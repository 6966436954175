/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  BaseEditor,
  Editor,
  Element as SlateElement,
  Range,
  Transforms
} from 'slate'
import { HistoryEditor } from 'slate-history'
import { ReactEditor } from 'slate-react'

import { CustomEditor, LinkElement } from '@/types/slate-custom-types'

export const isMarkActive = (editor: CustomEditor, format: string) => {
  const marks = Editor.marks(editor) as any
  return marks ? marks[format] === true : false
}

export const toggleMark = (
  editor: BaseEditor & ReactEditor & HistoryEditor,
  format: string
) => {
  const isActive = isMarkActive(editor, format)

  if (isActive) {
    Editor.removeMark(editor, format)
  } else {
    Editor.addMark(editor, format, true)
  }
}

export const isLinkActive = (editor: CustomEditor) => {
  const link = Editor.nodes(editor, {
    match: n =>
      !Editor.isEditor(n) &&
      SlateElement.isElement(n) &&
      n.type === 'component.link'
  })

  return !!link
}

export const unwrapLink = (editor: CustomEditor) => {
  Transforms.unwrapNodes(editor, {
    match: n =>
      !Editor.isEditor(n) &&
      SlateElement.isElement(n) &&
      n.type === 'component.link'
  })
}

export const wrapLink = (
  editor: BaseEditor & ReactEditor & HistoryEditor,
  url: string
) => {
  if (isLinkActive(editor)) {
    unwrapLink(editor)
  }

  const { selection } = editor
  const isCollapsed = selection && Range.isCollapsed(selection)
  const link: LinkElement = {
    type: 'component.link',
    url,
    children: isCollapsed ? [{ text: url }] : []
  }

  if (isCollapsed) {
    Transforms.insertNodes(editor, link)
  } else {
    Transforms.wrapNodes(editor, link, { split: true })
    Transforms.collapse(editor, { edge: 'end' })
  }
}
