import { FormikHelpers } from 'formik'
import { useState } from 'react'

import { DEFAULT_ERROR_MESSAGE } from '@/configs/message'
import { LoginFormValues } from '@/features/auth'
import { useAuth } from '@/lib/auth'
import { FormResponse } from '@/types'

const initialValues: LoginFormValues = {
  email: '',
  password: '',
  rememberMe: false
}

export const useLogin = ({ onSuccess }: { onSuccess?: () => void }) => {
  const { login } = useAuth()

  const [formResponse, setFormResponse] = useState<FormResponse>({
    isSuccess: false
  })

  const handleSubmit = async (
    values: LoginFormValues,
    actions: FormikHelpers<LoginFormValues>
  ) => {
    actions.setSubmitting(true)
    setFormResponse({
      isSuccess: false
    })
    try {
      await login(values)
      onSuccess?.()
    } catch (error) {
      const errorMessage = (error as string) ?? DEFAULT_ERROR_MESSAGE
      setFormResponse({
        isSuccess: false,
        message: errorMessage
      })
    } finally {
      actions.setSubmitting(false)
    }
  }

  return { initialValues, handleSubmit, formResponse }
}
