/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMutation } from 'react-query'

import { APIs } from '@/configs/api'
import { axios } from '@/lib/axios'
import { MutationConfig, queryClient } from '@/lib/react-query'

export type DeleteInterviewDTO = {
  projectId: string
}

export const deleteInterview = (data: DeleteInterviewDTO) =>
  axios.patch(APIs.PROJECT.DELETE, data, { withCredentials: true })

type UseDeleteInterviewOptions = {
  config?: MutationConfig<typeof deleteInterview>
}

export const useDeleteInterview = ({
  config
}: UseDeleteInterviewOptions = {}) => {
  return useMutation({
    onError: (_, __, context: any) => {
      if (context?.previousInterviews) {
        queryClient.setQueryData('interviews', context.previousInterviews)
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries('interviews')
    },
    ...config,
    mutationFn: deleteInterview
  })
}
