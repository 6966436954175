import { forwardRef, Ref } from 'react'

import { OrNull } from '@/types'

export const SlateNumberedList = forwardRef(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (props: any, ref: Ref<OrNull<HTMLHeadingElement>>) => {
    return <ol {...props} ref={ref} />
  }
)

SlateNumberedList.displayName = 'SlateNumberedList'

export default SlateNumberedList
