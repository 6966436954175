/* eslint-disable @typescript-eslint/no-explicit-any */
import { Editor, Element as SlateElement, Transforms } from 'slate'
import { useSlate } from 'slate-react'

import { SlateButton, SlateIcon } from '@/components'
import {
  CustomEditor,
  CustomElement,
  SlateElementType,
  SlateTextAlignType
} from '@/types'

const LIST_TYPES = ['component.numbered-list', 'component.bulleted-list']
const TEXT_ALIGN_TYPES = ['left', 'center', 'right', 'justify']

export interface ISlateBlockButtonProps {
  format: SlateElementType | SlateTextAlignType
  icon: string
}

export const SlateBlockButton = ({ format, icon }: ISlateBlockButtonProps) => {
  const editor = useSlate()

  const isBlockActive = (
    editor: CustomEditor,
    format: SlateElementType | SlateTextAlignType,
    blockType: 'align' | 'type'
  ) => {
    const { selection } = editor
    if (!selection) return false

    const [match] = Array.from(
      Editor.nodes(editor, {
        at: Editor.unhangRange(editor, selection),
        match: n =>
          !Editor.isEditor(n) &&
          SlateElement.isElement(n) &&
          (n as any)[blockType] === format
      })
    )

    return !!match
  }

  const toggleBlock = (
    editor: CustomEditor,
    format: SlateElementType | SlateTextAlignType
  ) => {
    const isActive = isBlockActive(
      editor,
      format,
      TEXT_ALIGN_TYPES.includes(format) ? 'align' : 'type'
    )
    const isList = LIST_TYPES.includes(format)

    Transforms.unwrapNodes(editor, {
      match: n =>
        !Editor.isEditor(n) &&
        SlateElement.isElement(n) &&
        LIST_TYPES.includes(n.type) &&
        !TEXT_ALIGN_TYPES.includes(format),
      split: true
    })
    let newProperties: Partial<SlateElement>
    if (TEXT_ALIGN_TYPES.includes(format)) {
      newProperties = {
        align: isActive ? undefined : format
      }
    } else {
      newProperties = {
        type: isActive
          ? 'component.paragraph'
          : isList
          ? 'component.list-item'
          : (format as SlateElementType)
      }
    }
    Transforms.setNodes<SlateElement>(editor, newProperties)

    if (!isActive && isList) {
      const block = {
        type: format,
        children: []
      }
      Transforms.wrapNodes(editor, block as CustomElement)
    }
  }

  const handleMouseDown = (event: { preventDefault: () => void }) => {
    event.preventDefault()
    toggleBlock(editor, format)
  }

  return (
    <SlateButton
      active={isBlockActive(
        editor,
        format,
        TEXT_ALIGN_TYPES.includes(format) ? 'align' : 'type'
      )}
      onMouseDown={handleMouseDown}
    >
      <SlateIcon>{icon}</SlateIcon>
    </SlateButton>
  )
}

SlateBlockButton.displayName = 'SlateBlockButton'

export default SlateBlockButton
