import { STORAGE_PREFIX } from '@/configs/storage'

export const storage = {
  getToken: () => {
    try {
      return JSON.parse(
        window.localStorage.getItem(`${STORAGE_PREFIX}token`) as string
      )
    } catch (error) {
      window.localStorage.removeItem(`${STORAGE_PREFIX}token`)
      return null
    }
  },
  setToken: (token: string) => {
    window.localStorage.setItem(`${STORAGE_PREFIX}token`, JSON.stringify(token))
  },
  clearToken: () => {
    window.localStorage.removeItem(`${STORAGE_PREFIX}token`)
  }
}
