import { Popover, Transition } from '@headlessui/react'
import classNames from 'classnames'
import { FC, Fragment, memo } from 'react'

import { AppLogo, Avatar } from '@/components'
import { useAppHeader } from '@/hooks'

export interface IAppHeaderProps {}

export const AppHeader: FC<IAppHeaderProps> = memo(({}: IAppHeaderProps) => {
  const {
    user,
    tab,
    projectId,
    interviewId,
    isDashboardPage,
    isProjectPage,
    isInterviewPage,
    onClickLogout
  } = useAppHeader()

  return (
    <header className='sticky top-0 z-[9999] min-h-[60px] shadow-sm'>
      <div className='w-full bg-white font-syne font-normal'>
        <div className='relative flex flex-row items-center px-6 md:px-10'>
          <AppLogo className='absolute top-4 left-4 xl:left-10' color='#000' />
          <div className='mx-auto hidden max-w-6xl md:flex'>
            {isDashboardPage && (
              <>
                <a
                  className={`border-b-2 ${
                    tab === 'workspace-index'
                      ? 'border-indigo-500 text-gray-900'
                      : 'border-transparent text-gray-500'
                  } mx-2 px-1 py-4`}
                  href='/'
                >
                  My workspace
                </a>
                <a
                  className={`border-b-2 ${
                    tab === 'workspace-settings'
                      ? 'border-indigo-500 text-gray-900'
                      : 'border-transparent text-gray-500'
                  } mx-2 px-1 py-4`}
                  href='/settings'
                >
                  Settings
                </a>
              </>
            )}

            {isProjectPage && (
              <>
                <a
                  className={`border-b-2 ${
                    tab === 'project-index'
                      ? 'border-indigo-500 text-gray-900'
                      : 'border-transparent text-gray-500'
                  } mx-2 px-1 py-4`}
                  href={`/project/${projectId}`}
                >
                  Project
                </a>
                <a
                  className={`border-b-2 ${
                    tab === 'project-settings'
                      ? 'border-indigo-500 text-gray-900'
                      : 'border-transparent text-gray-500'
                  } mx-2 px-1 py-4`}
                  href={`/project/${projectId}/settings`}
                >
                  Settings
                </a>
              </>
            )}

            {isInterviewPage && (
              <>
                <a
                  className={`border-b-2 ${
                    tab === 'interview-detail'
                      ? 'border-indigo-500 text-gray-900'
                      : 'border-transparent text-gray-500'
                  } mx-2 px-1 py-4`}
                  href={`/interview/${interviewId}`}
                >
                  Interview
                </a>
                <a
                  className={`border-b-2 ${
                    tab === 'interview-settings'
                      ? 'border-indigo-500 text-gray-900'
                      : 'border-transparent text-gray-500'
                  } mx-2 px-1 py-4`}
                  href={`/interview/${interviewId}/settings`}
                >
                  Settings
                </a>
              </>
            )}
          </div>

          <div className='absolute top-4 right-4 xl:right-10'>
            <Popover className='relative h-8 w-8'>
              {({ open }) => (
                <>
                  <Popover.Button
                    className={classNames(
                      open ? 'text-gray-900' : 'text-gray-500',
                      'group bg-white rounded-full inline-flex items-center text-base font-medium ring-2 ring-offset-2 ring-gray-500 text-gray-500 focus:text-indigo-500 focus:outline-none focus:ring-indigo-500'
                    )}
                  >
                    {user && <Avatar src={user.avatar} alt={user?.firstName} />}
                  </Popover.Button>

                  <Transition
                    as={Fragment}
                    enter='transition ease-out duration-200'
                    enterFrom='opacity-0 translate-y-1'
                    enterTo='opacity-100 translate-y-0'
                    leave='transition ease-in duration-150'
                    leaveFrom='opacity-100 translate-y-0'
                    leaveTo='opacity-0 translate-y-1'
                  >
                    <Popover.Panel className='absolute left-full z-10 mt-3 w-screen max-w-[10rem] -translate-x-full rounded-md sm:px-0'>
                      <div className='ring-opacity/5 rounded-md shadow-lg hover:ring-1 hover:ring-indigo-500'>
                        <div className='relative grid gap-2 rounded-md bg-white sm:gap-4'>
                          <button
                            className='block rounded-md p-3 text-right transition duration-150 ease-in-out hover:bg-gray-100'
                            onClick={onClickLogout}
                          >
                            Logout
                          </button>
                        </div>
                      </div>
                    </Popover.Panel>
                  </Transition>
                </>
              )}
            </Popover>
          </div>
        </div>
      </div>
    </header>
  )
})

AppHeader.displayName = 'AppHeader'

export default AppHeader
