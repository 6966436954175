export const DEFAULT_ERROR_MESSAGE = 'Something went wrong'

export const DEFAULT_REGISTER_MESSAGE =
  'Registration request submitted successfully. Please check your inbox.'

export const LOG_IN_ERROR_MESSAGE = 'Invalid email or password!'

export const REQUIRED_MESSAGE = 'Required'

export const INVALID_EMAIL_MESSAGE = 'Invalid email!'

export const MAX_LENGTH_MESSAGE = (max: number) => `Maximum length is ${max}`
export const MIN_LENGTH_MESSAGE = (min: number) => `Minimum length is ${min}`

export const SUCCESS_RESET_PASSWORD_MESSAGE =
  'If a matching account was found an email was sent to allow you to reset your password'

export const REFRESH_ACCESS_TOKEN_ERROR = 'RefreshAccessTokenError'
