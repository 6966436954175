import classNames from 'classnames'
import { forwardRef, PropsWithChildren, Ref } from 'react'

import { BaseProps } from '@/types'

export const SlateIcon = forwardRef(
  (
    { className, ...props }: PropsWithChildren<BaseProps>,
    ref: Ref<HTMLSpanElement>
  ) => (
    <span
      {...props}
      ref={ref}
      className={classNames(
        'material-symbols-outlined !text-[18px] align-text-bottom',
        className
      )}
    />
  )
)

SlateIcon.displayName = 'SlateIcon'

export default SlateIcon
