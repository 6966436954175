/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMutation } from 'react-query'

import { APIs } from '@/configs/api'
import { Project } from '@/features/project'
import { axios } from '@/lib/axios'
import { MutationConfig, queryClient } from '@/lib/react-query'

export type UpdateProjectDTO = {
  id: number
  title?: string
  description?: string
  questions?: string
  questionsContent?: string
}

export const updateProject = (data: UpdateProjectDTO): Promise<Project> =>
  axios.patch(APIs.PROJECT.UPDATE, data, { withCredentials: true })

type UseUpdateProjectOptions = {
  config?: MutationConfig<typeof updateProject>
}

export const useUpdateProject = ({ config }: UseUpdateProjectOptions = {}) => {
  return useMutation({
    onError: (_, __, context: any) => {
      if (context?.previousProjects) {
        queryClient.setQueryData('projects', context.previousProjects)
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries('projects')
    },
    ...config,
    mutationFn: updateProject
  })
}
