import { Descendant, Text } from 'slate'

export const serialize = (node: Descendant): string => {
  if (Text.isText(node)) {
    return node.text
  }
  const children = node.children?.map(child => serialize(child)).join('\n')
  return children
}

export const serializeSlateValue = (nodes: Descendant[]): string => {
  return nodes.map(node => serialize(node)).join('\n')
}
