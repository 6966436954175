import { forwardRef, Ref } from 'react'

import { OrNull } from '@/types'

export const SlateQuote = forwardRef(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (props: any, ref: Ref<OrNull<HTMLQuoteElement>>) => {
    return <blockquote {...props} ref={ref} />
  }
)

SlateQuote.displayName = 'SlateQuote'

export default SlateQuote
