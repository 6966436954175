// import { Descendant } from 'slate'

export enum InterviewStatusEnum {
  Initial = 'initial',
  Uploading = 'uploading',
  Uploaded = 'uploaded',
  UploadFailed = 'upload-failed',
  TranscriptInProgress = 'transcript-in-progress',
  TranscriptFailed = 'transcript-failed',
  TranscriptCompleted = 'transcript-completed',
  MatchQuestionsInProgress = 'match-questions-in-progress',
  MatchQuestionsFailed = 'match-questions-failed',
  MatchQuestionsCompleted = 'match-questions-completed',
  SentimentInProgress = 'sentiment-in-progress',
  SentimentFailed = 'sentiment-failed',
  SentimentCompleted = 'sentiment-completed',
  ExtractAnswerInProgress = 'extract-answer-in-progress',
  ExtractAnswerFailed = 'extract-answer-failed',
  ExtractAnswerCompleted = 'extract-answer-completed',
  TranscriptGenerationInProgress = 'transcript-generation-in-progress',
  TranscriptGenerationFailed = 'transcript-generation-failed',
  TranscriptGenerationCompleted = 'transcript-generation-completed'
}

export type Interview = {
  id?: number
  title?: string
  description?: string
  status: InterviewStatusEnum
  source?: string
  sourceFormat?: string
  transcript?: string
  transcriptUrl?: string
  transcriptMatchedQuestionsUrl?: string
  transcriptSentimentUrl?: string
  transcriptExtractAnswerUrl?: string
  project?: {
    id: number
    title: string
    description: string
  }
}
