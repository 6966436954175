import { FC, memo } from 'react'

import { Project } from '@/features/project'
import { useProjectCard } from '@/hooks'

export interface IProjectCard {
  project: Project
}

export const ProjectCard: FC<IProjectCard> = memo(
  ({ project }: IProjectCard) => {
    const { onClickProjectCard } = useProjectCard(project)

    return (
      <div
        className='flex flex-col rounded-lg border border-gray-300 p-5 text-sm hover:cursor-pointer hover:shadow-md'
        onClick={onClickProjectCard}
      >
        <div className='mb-1 truncate font-medium text-gray-900'>
          {project.title ?? 'Research project title'}
        </div>
        <div className='truncate text-gray-500'>
          {project.description ?? 'Research project description'}
        </div>
      </div>
    )
  }
)

ProjectCard.displayName = 'ProjectCard'

export default ProjectCard
