/* eslint-disable @typescript-eslint/no-unused-vars */
import { useInfiniteQuery } from 'react-query'

import { APIs } from '@/configs/api'
import { Transcript } from '@/features/transcript'
import { axios } from '@/lib/axios'
import { ExtractFnReturnType, InfiniteQueryConfig } from '@/lib/react-query'

export interface GetTranscriptsDto {
  transcripts: Transcript[]
  nextOffset?: number
  total: number
}

export const getTranscripts = (
  interviewId: number,
  offset?: number,
  limit?: number
): Promise<GetTranscriptsDto> =>
  axios.get(
    `${APIs.TRANSCRIPT.GET_LIST}?interviewId=${interviewId}&offset=${offset}&limit=${limit}`,
    {
      withCredentials: true
    }
  )

type InfiniteQueryFnType = typeof getTranscripts

type UseTranscriptsOptions = {
  interviewId: number
  offset?: number
  limit?: number
  config?: InfiniteQueryConfig<InfiniteQueryFnType>
}

export const useTranscripts = ({
  interviewId,
  offset = 0,
  limit = 10,
  config
}: UseTranscriptsOptions) => {
  return useInfiniteQuery<ExtractFnReturnType<InfiniteQueryFnType>>({
    ...config,
    queryKey: ['transcripts'],
    queryFn: ({ pageParam = 0 }) =>
      getTranscripts(interviewId, pageParam, limit),
    getNextPageParam: (lastPage, pages) => lastPage.nextOffset
  })
}
