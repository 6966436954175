/* eslint-disable @typescript-eslint/no-explicit-any */
import Axios, { AxiosError } from 'axios'

import { APIs } from '@/configs/api'
import { API_URL } from '@/configs/env'
import { DEFAULT_ERROR_MESSAGE } from '@/configs/message'
import { refreshToken } from '@/features/auth'

export type AxiosErrorBaseDataType = {
  statusCode?: number
  message?: string
  apiErrorCode?: string
  reason?: string
}

export const axios = Axios.create({
  baseURL: API_URL
})

const REFRESH_TOKEN_BLACK_LIST = [
  APIs.AUTH.LOGIN,
  APIs.AUTH.REGISTER,
  APIs.AUTH.REFRESH_TOKEN
]

axios.interceptors.response.use(
  response => response.data,
  async (error: AxiosError<AxiosErrorBaseDataType, any>) => {
    const config = error?.config
    if (error?.response?.status === 401) {
      if (config?.url && !REFRESH_TOKEN_BLACK_LIST.includes(config.url)) {
        await refreshToken()
        return axios(config)
      }

      if (config?.url === APIs.AUTH.REFRESH_TOKEN) {
        // 401 while refreshing token, signing out by reloading
        window.location.assign(window.location.origin as unknown as string)
      }
    }
    return Promise.reject(
      error.response?.data?.message ?? DEFAULT_ERROR_MESSAGE
    )
  }
)
