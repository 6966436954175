import { useEffect } from 'react'

import { useTranscripts } from '@/features/transcript'
import { useOnScreen } from '@/hooks'

export const useInterviewTranscriptList = (interviewId: number) => {
  const {
    data: transcriptInfiniteData,
    fetchNextPage,
    hasNextPage,
    isFetching
  } = useTranscripts({ interviewId })

  const { measureRef, isIntersecting, observer } = useOnScreen({
    threshold: 1.0
  })

  useEffect(() => {
    if (isIntersecting && hasNextPage) {
      fetchNextPage()
      observer?.disconnect()
    }

    return () => {
      observer?.disconnect()
    }
  }, [isIntersecting, hasNextPage, fetchNextPage])

  return {
    transcriptInfiniteData,
    isFetching,
    hasNextPage,
    measureRef
  }
}
