import classNames from 'classnames'
import { FC, memo, ReactNode } from 'react'

import { AppHead, AppHeader } from '@/components'

export interface IAppLayoutProps {
  children: ReactNode
  className?: string
}

export const AppLayout: FC<IAppLayoutProps> = memo(
  ({ children, className }: IAppLayoutProps) => (
    <>
      <AppHead />
      <AppHeader />
      <main className={classNames('w-full', className)}>
        <div className='md:py-15 mx-auto min-h-screen max-w-6xl px-4 py-10'>
          {children}
        </div>
      </main>
    </>
  )
)

AppLayout.displayName = 'AppLayout'

export default AppLayout
