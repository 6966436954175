/* eslint-disable @typescript-eslint/no-explicit-any */
import classNames from 'classnames'
import { forwardRef, PropsWithChildren, Ref } from 'react'

import { BaseProps } from '@/types'

export const SlateButton = forwardRef(
  (
    {
      className,
      active,
      reversed,
      ...props
    }: PropsWithChildren<
      {
        active: boolean
        reversed: boolean
      } & BaseProps
    >,
    ref: Ref<HTMLSpanElement>
  ) => (
    <span
      {...props}
      ref={ref}
      className={classNames(
        className,
        'cursor-pointer hover:bg-gray-200 rounded-sm py-1 px-[9px]',
        {
          'text-white': reversed && active,
          'text-[#aaa]': reversed && !active,
          'text-black': !reversed && active,
          'text-[#ccc]': !reversed && !active
        }
      )}
    />
  )
)

SlateButton.displayName = 'SlateButton'

export default SlateButton
