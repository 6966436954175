import { PlusIcon } from '@heroicons/react/solid'
import { FC, memo } from 'react'

import { Button, ProjectPlusIcon } from '@/components'
import { Project, ProjectCard } from '@/features/project'

export interface IProjectCardList {
  projects: Project[]
  onClickNewProject: () => void
}

export const ProjectCardList: FC<IProjectCardList> = memo(
  ({ projects, onClickNewProject }: IProjectCardList) => (
    <div className='flex w-full flex-wrap border-y border-gray-300'>
      {projects.length > 0 && (
        <>
          <Button
            type='button'
            className='my-8 text-sm font-medium text-white'
            onClick={onClickNewProject}
          >
            Add a new research project
          </Button>

          <div className='mb-8 flex w-full flex-col'>
            <h3 className='mb-4 text-[1.125rem] font-medium text-gray-900'>
              Projects
            </h3>

            <div
              className='grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2 sm:gap-x-6 md:grid-cols-3'
              role='list'
            >
              {projects.map(project => (
                <ProjectCard project={project} key={project.id} />
              ))}
            </div>
          </div>
        </>
      )}

      {projects.length === 0 && (
        <div className='my-24 flex w-full flex-col items-center text-center'>
          <ProjectPlusIcon className='mx-auto h-12 w-12 text-gray-400' />
          <h3 className='mt-2 text-sm font-medium text-gray-900'>
            No projects
          </h3>
          <p className='mt-1 text-sm text-gray-500'>
            Get started by creating a new project.
          </p>
          <div className='mt-6'>
            <Button
              type='button'
              className='text-sm font-medium text-white'
              onClick={onClickNewProject}
            >
              <PlusIcon className='-ml-1 mr-2 h-5 w-5' aria-hidden='true' />
              New Project
            </Button>
          </div>
        </div>
      )}
    </div>
  )
)

ProjectCardList.displayName = 'ProjectCardList'

export default ProjectCardList
