import { ExclamationCircleIcon } from '@heroicons/react/solid'
import { FC, Fragment, memo } from 'react'

import { InterviewTranscript, Spinner } from '@/components'
import { useInterviewTranscriptList } from '@/hooks'

export interface IInterviewTranscriptListProps {
  interviewId: number
}

export const InterviewTranscriptList: FC<IInterviewTranscriptListProps> = memo(
  ({ interviewId }: IInterviewTranscriptListProps) => {
    const { transcriptInfiniteData, isFetching, hasNextPage, measureRef } =
      useInterviewTranscriptList(interviewId)

    return (
      <div className='flex flex-col'>
        {transcriptInfiniteData?.pages?.map((pageDto, pageIndex) => (
          <Fragment key={pageIndex}>
            {pageDto?.transcripts?.map((transcript, index) => (
              <InterviewTranscript
                key={transcript.id}
                transcript={transcript}
                measureRef={
                  index === pageDto?.transcripts?.length - 1 &&
                  pageIndex === transcriptInfiniteData?.pages?.length - 1
                    ? measureRef
                    : undefined
                }
              />
            ))}
          </Fragment>
        ))}
        {isFetching && (
          <div className='w-ful inline rounded border border-t border-b-0 border-indigo-300 bg-indigo-200 p-4'>
            <Spinner size='sm' className='mr-2 inline' />
            Loading more...
          </div>
        )}
        {!hasNextPage && (
          <div className='w-ful inline rounded border border-t border-b-0 border-indigo-300 bg-indigo-200 p-4'>
            <ExclamationCircleIcon
              className='mr-2 inline h-5 w-5 text-indigo-400'
              aria-hidden='true'
            />
            You have reached the end of transcripts
          </div>
        )}
      </div>
    )
  }
)

InterviewTranscriptList.displayName = 'InterviewTranscriptList'

export default InterviewTranscriptList
