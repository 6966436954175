import { Descendant } from 'slate'

import { EMPTY_EDITOR_VALUE } from '@/constants/slate'
import { InterviewStatusEnum } from '@/features/interview'
import { Project, ProjectDTO } from '@/features/project'

export const normalizeProjectDto = (data?: ProjectDTO): Project => ({
  id: data?.id,
  title: data?.title,
  description: data?.description,
  questions: data?.questions,
  questionsContent: data?.questionsContent
    ? (JSON.parse(data?.questionsContent) as Descendant[])
    : EMPTY_EDITOR_VALUE,
  interviews:
    data?.interviews?.map(interviewDto => ({
      id: interviewDto.id,
      title: interviewDto.title,
      description: interviewDto.description,
      status: interviewDto.status as InterviewStatusEnum,
      source: interviewDto.source,
      sourceFormat: interviewDto.sourceFormat,
      transcriptUrl: interviewDto.transcriptUrl,
      transcriptMatchedQuestionsUrl: interviewDto.transcriptMatchedQuestionsUrl,
      transcriptSentimentUrl: interviewDto.transcriptSentimentUrl,
      project: {
        id: interviewDto.project?.id as number,
        title: interviewDto.project?.title as string,
        description: interviewDto.project?.description as string
      }
    })) ?? []
})
