import classNames from 'classnames'
import { FC, InputHTMLAttributes, ReactNode } from 'react'

export interface ICheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: ReactNode
  hasError?: boolean
  className?: string
}

export const Checkbox: FC<ICheckboxProps> = ({
  label,
  hasError = false,
  ...props
}: ICheckboxProps) => {
  return (
    <label className='flex flex-row items-center '>
      <input
        type='checkbox'
        className={classNames(
          'w-4 h-4 text-indigo-600 rounded border-gray-300 focus:ring-indigo-500 appearance-none',
          {
            'border-red-500': hasError
          }
        )}
        {...props}
      />

      {label && (
        <div className='ml-2 select-none text-sm text-gray-900'>{label}</div>
      )}
    </label>
  )
}

Checkbox.displayName = 'Checkbox'

export default Checkbox
