import { Navigate, Route, Routes } from 'react-router-dom'

import {
  InterviewDetailPage,
  InterviewSettingsPage
} from '@/features/interview'

export const InterviewRoutes = () => {
  return (
    <Routes>
      <Route path=':interviewId' element={<InterviewDetailPage />} />
      <Route path=':interviewId/settings' element={<InterviewSettingsPage />} />
      <Route path='*' element={<Navigate to='/' />} />
    </Routes>
  )
}
