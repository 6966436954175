import classNames from 'classnames'
import { FC, memo } from 'react'

export interface IPageTitleProps {
  title?: string
  className?: string
}

export const PageTitle: FC<IPageTitleProps> = memo(
  ({ title, className = '' }: IPageTitleProps) => (
    <h2 className={classNames('font-syne text-4xl font-medium', className)}>
      {title}
    </h2>
  )
)

PageTitle.displayName = 'PageTitle'

export default PageTitle
