import { Query, QueryKey, useQuery } from 'react-query'

import { APIs } from '@/configs/api'
import { InterviewDTO } from '@/features/interview'
import { axios } from '@/lib/axios'
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query'

export type ProjectDTO = {
  id: number
  title?: string
  description?: string
  questions?: string
  questionsContent: string
  interviews?: InterviewDTO[]
}

export const getProject = (projectId: number): Promise<ProjectDTO> => {
  return axios.get(`${APIs.PROJECT.GET}/${projectId}`, {
    withCredentials: true
  })
}

type QueryFnType = typeof getProject

type UseProjectOptions = {
  projectId: number
  refetchInterval?:
    | number
    | false
    | ((
        data: ProjectDTO | undefined,
        query: Query<ProjectDTO, unknown, ProjectDTO, QueryKey>
      ) => number | false)
  config?: QueryConfig<QueryFnType>
}

export const useProject = ({
  projectId,
  refetchInterval = false,
  config
}: UseProjectOptions) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    refetchInterval: refetchInterval,
    queryKey: ['project', projectId],
    queryFn: () => getProject(projectId)
  })
}
