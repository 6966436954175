import TextareaAutosize from 'react-textarea-autosize'

import { InterviewTranscriptList, Tab } from '@/components'
import { useInterviewDetailPage } from '@/hooks'

export const InterviewDetailPage = () => {
  const {
    interviewData,
    activeTab,
    tabs,
    onChangeTitle,
    onChangeDescription,
    onTabChange
  } = useInterviewDetailPage()

  return (
    <div className='flex flex-col'>
      <TextareaAutosize
        name='title'
        value={interviewData.title}
        onInput={onChangeTitle}
        placeholder='Title of the interview (change to any title you want)'
        className='mt-6 w-full resize-none border-none py-4 px-2 font-syne text-4xl font-semibold text-black placeholder:text-black focus:outline-none focus:ring-0'
      />

      <TextareaAutosize
        name='description'
        value={interviewData.description}
        onInput={onChangeDescription}
        placeholder='Descriptions'
        className='w-full resize-none border-none p-2 font-inter text-base font-normal text-black placeholder:text-black focus:outline-none focus:ring-0'
      />

      <div>
        <Tab
          activeTab={activeTab}
          tabs={tabs}
          className='mb-6'
          onTabChange={onTabChange}
        />

        {activeTab?.slug === 'tab-transcript' && interviewData?.id && (
          <InterviewTranscriptList interviewId={interviewData.id} />
        )}
      </div>
    </div>
  )
}

InterviewDetailPage.displayName = 'InterviewDetailPage'

export default InterviewDetailPage
