/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMutation } from 'react-query'

import { APIs } from '@/configs/api'
import { Transcript } from '@/features/transcript'
import { axios } from '@/lib/axios'
import { MutationConfig, queryClient } from '@/lib/react-query'

export type UpdateTranscriptDTO = {
  id: number
  transcriptContent: string
  commentContent: string
}

export const updateTranscript = (
  data: UpdateTranscriptDTO
): Promise<Transcript> =>
  axios.patch(APIs.TRANSCRIPT.UPDATE, data, { withCredentials: true })

type UseUpdateTranscriptOptions = {
  config?: MutationConfig<typeof updateTranscript>
}

export const useUpdateTranscript = ({
  config
}: UseUpdateTranscriptOptions = {}) => {
  return useMutation({
    onError: (_, __, context: any) => {
      if (context?.previousTranscripts) {
        queryClient.setQueryData('transcripts', context.previousTranscripts)
      }
    },
    ...config,
    mutationFn: updateTranscript
  })
}
