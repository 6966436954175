import classNames from 'classnames'
import { FC, memo } from 'react'

export interface IProjectPlusIconProps {
  width?: number
  height?: number
  className?: string
}

export const ProjectPlusIcon: FC<IProjectPlusIconProps> = memo(
  ({ width = 24, height = 24, className = '' }: IProjectPlusIconProps) => {
    return (
      <svg
        width={width}
        height={height}
        fill='none'
        viewBox='0 0 24 24'
        stroke='currentColor'
        aria-hidden='true'
        className={classNames(className)}
      >
        <path
          vectorEffect='non-scaling-stroke'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth={2}
          d='M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z'
        />
      </svg>
    )
  }
)

ProjectPlusIcon.displayName = 'ProjectPlusIcon'

export default ProjectPlusIcon
