import { useCallback, useMemo } from 'react'
import { useLocation } from 'react-router-dom'

import { useAuth } from '@/lib/auth'

export const useAppHeader = () => {
  const { user, logout } = useAuth()
  const location = useLocation()

  const isDashboardPage = useMemo(
    () => location.pathname === '/' || location.pathname === '/settings',
    [location.pathname]
  )

  const isProjectPage = useMemo(
    () => location.pathname.startsWith('/project/'),
    [location.pathname]
  )

  const projectId = useMemo(() => {
    if (location.pathname.startsWith('/project/')) {
      return location.pathname.split('/')[2]
    }
  }, [location.pathname])

  const isInterviewPage = useMemo(
    () => location.pathname.startsWith('/interview/'),
    [location.pathname]
  )

  const interviewId = useMemo(() => {
    if (location.pathname.startsWith('/interview/')) {
      return location.pathname.split('/')[2]
    }
  }, [location.pathname])

  const tab = useMemo(() => {
    if (location.pathname === '/') {
      return 'workspace-index'
    }
    if (location.pathname === '/settings') {
      return 'workspace-settings'
    }

    if (location.pathname.startsWith('/project/')) {
      if (location.pathname.includes('/settings')) {
        return 'project-settings'
      } else {
        return 'project-index'
      }
    }

    if (location.pathname.startsWith('/interview/')) {
      if (location.pathname.includes('/settings')) {
        return 'interview-settings'
      } else {
        return 'interview-detail'
      }
    }
  }, [location.pathname])

  const onClickLogout = useCallback(async () => {
    await logout()
  }, [logout])

  return {
    user,
    tab,
    projectId,
    interviewId,
    isDashboardPage,
    isProjectPage,
    isInterviewPage,
    onClickLogout
  }
}
