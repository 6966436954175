import { FormikHelpers } from 'formik'
import { useState } from 'react'

import { DEFAULT_ERROR_MESSAGE } from '@/configs/message'
import {
  RegisterVerificationFormValues,
  verifyRegistrationCode
} from '@/features/auth'
import { FormResponse } from '@/types'

const initialValues: RegisterVerificationFormValues = {
  code: ''
}

export const useRegisterVerification = (
  userEmail: string,
  onSuccess?: () => void
): {
  initialValues: RegisterVerificationFormValues
  handleSubmit: (
    values: RegisterVerificationFormValues,
    actions: FormikHelpers<RegisterVerificationFormValues>
  ) => void
  formResponse: FormResponse
} => {
  const [formResponse, setFormResponse] = useState<FormResponse>({
    isSuccess: false
  })

  const handleSubmit = async (
    values: RegisterVerificationFormValues,
    actions: FormikHelpers<RegisterVerificationFormValues>
  ) => {
    actions.setSubmitting(true)
    setFormResponse({
      isSuccess: false
    })

    try {
      await verifyRegistrationCode({ email: userEmail, code: values.code })
      onSuccess?.()
    } catch (error) {
      const errorMessage = (error as string) ?? DEFAULT_ERROR_MESSAGE
      setFormResponse({
        isSuccess: false,
        message: errorMessage
      })
    } finally {
      actions.setSubmitting(false)
    }
  }

  return { initialValues, handleSubmit, formResponse }
}
