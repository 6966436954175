import { useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { PATHS } from '@/configs/misc'
import { RegisterationStep } from '@/features/auth'

export const useRegisterPage = () => {
  const [step, setStep] = useState<RegisterationStep>('registration-request')
  const [userEmail, setUserEmail] = useState('')

  const navigate = useNavigate()

  const onRegisterationRequestSuccess = useCallback((email: string) => {
    setStep('registration-verification')
    setUserEmail(email)
  }, [])

  const onRegisterVerificationSuccess = useCallback(() => {
    navigate(PATHS.BASE)
  }, [navigate])

  return {
    step,
    userEmail,
    onRegisterationRequestSuccess,
    onRegisterVerificationSuccess
  }
}
