import classNames from 'classnames'
import { Form, Formik } from 'formik'
import { FC, memo } from 'react'
import * as Yup from 'yup'

import { Button, FormField, FormFieldError } from '@/components'
import { MIN_LENGTH_MESSAGE } from '@/configs/message'
import { PATHS } from '@/configs/misc'
import { useResetPassword } from '@/hooks'

const validationSchema = Yup.object().shape({
  password: Yup.string()
    .min(8, MIN_LENGTH_MESSAGE(8))
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])/,
      'Must contain uppercase and lowercase letters'
    )
    .matches(/^(?=.*[0-9])/, 'Must contain at least one number')
    .matches(
      /[`!@#$%^&*()_+=[\]{};:"\\|,.<>/?~-]/,
      'Must contains one special character (ex. !@#$)'
    )
    .required('Password is required'),
  confirmPassword: Yup.string()
    .test('password-match', 'Password does not match', function (value) {
      return this.parent.password === value
    })
    .required('Please retype your password')
})

type ResetPasswordFormProps = {
  onSuccess?: () => void
}

export const ResetPasswordForm: FC<ResetPasswordFormProps> = memo(
  ({ onSuccess }: ResetPasswordFormProps) => {
    const { initialValues, handleSubmit, formResponse } =
      useResetPassword(onSuccess)

    return (
      <div className='mt-8 sm:mx-auto sm:w-full sm:max-w-md'>
        <div className='bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10'>
          {formResponse?.isSuccess ? (
            <div className='text-sm'>
              {formResponse?.message}
              <a
                href={PATHS.AUTH.LOGIN}
                className='pl-1 font-medium text-indigo-600 hover:text-indigo-500'
              >
                Login
              </a>
            </div>
          ) : (
            <Formik
              enableReinitialize
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ isSubmitting }) => (
                <Form noValidate className='space-y-6'>
                  <div>
                    <FormField
                      name='password'
                      type='password'
                      label='New Password'
                      required
                    />
                    <FormFieldError name='password' />
                  </div>

                  <div>
                    <FormField
                      name='confirmPassword'
                      type='password'
                      label='Confirm Password'
                      required
                    />
                    <FormFieldError name='confirmPassword' />
                  </div>

                  <div>
                    <Button
                      type='submit'
                      disabled={isSubmitting}
                      isLoading={isSubmitting}
                      className='w-full'
                    >
                      Reset Password
                    </Button>

                    {formResponse?.message && (
                      <div
                        className={classNames('mt-2 text-sm', {
                          'text-red-600': formResponse?.isSuccess === false
                        })}
                      >
                        {formResponse?.message}
                      </div>
                    )}
                  </div>
                </Form>
              )}
            </Formik>
          )}
        </div>
      </div>
    )
  }
)

ResetPasswordForm.displayName = 'ResetPasswordForm'

export default ResetPasswordForm
