import { FC, memo } from 'react'

import { Interview } from '@/features/interview'
import { InterviewCard } from '@/features/project'

export interface IInterviewCardList {
  interviews: Interview[]
  enablePolling: () => void
  disablePolling: () => void
}

export const InterviewCardList: FC<IInterviewCardList> = memo(
  ({ interviews, enablePolling, disablePolling }: IInterviewCardList) => (
    <ul
      role='list'
      className='grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2 sm:gap-x-6 md:grid-cols-3 lg:grid-cols-4 xl:gap-x-8'
    >
      {interviews.map(interview => (
        <li key={interview.id} className='relative'>
          <InterviewCard
            interview={interview}
            enablePolling={enablePolling}
            disablePolling={disablePolling}
          />
        </li>
      ))}
    </ul>
  )
)

InterviewCardList.displayName = 'InterviewCardList'

export default InterviewCardList
