import classNames from 'classnames'
import { FC, memo } from 'react'

import { ITabItem } from '@/types'

export interface ITabProps {
  className?: string
  tabs: ITabItem[]
  activeTab: ITabItem
  onTabChange?: (value: ITabItem) => void
}

export const Tab: FC<ITabProps> = memo(
  ({ tabs, activeTab, onTabChange, className = '' }: ITabProps) => {
    const handleTabItemClick = (item: ITabItem) => {
      onTabChange?.(item)
    }

    return (
      <div className={className}>
        <div className='border-b border-gray-200'>
          <nav className='-mb-px flex' aria-label='Tabs'>
            {tabs.map(tab => (
              <a
                key={tab.slug}
                className={classNames(
                  tab.slug === activeTab.slug
                    ? 'border-indigo-500 text-indigo-600'
                    : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                  'py-4 px-1 mr-6 text-center border-b-2 font-medium text-sm cursor-pointer'
                )}
                onClick={() => handleTabItemClick(tab)}
              >
                {tab.label}
                {tab.badge && (
                  <span
                    className={classNames(
                      tab.slug === activeTab.slug
                        ? 'bg-indigo-100 text-indigo-600'
                        : 'bg-gray-100 text-gray-900',
                      'hidden ml-3 py-0.5 px-2.5 rounded-full text-xs font-medium md:inline-block'
                    )}
                  >
                    {tab.badge}
                  </span>
                )}
              </a>
            ))}
          </nav>
        </div>
      </div>
    )
  }
)

Tab.displayName = 'Tab'

export default Tab
