import { Form, Formik } from 'formik'
import { FC, memo } from 'react'
import * as Yup from 'yup'

import { Button, FormField, FormFieldError } from '@/components'
import { MIN_LENGTH_MESSAGE } from '@/configs/message'
import { useRegister } from '@/hooks'

const validationSchema = Yup.object().shape({
  firstName: Yup.string().required('First name is required'),
  lastName: Yup.string().required('Last name is required'),
  email: Yup.string().email('Invalid email').required('Email is required'),
  password: Yup.string()
    .min(8, MIN_LENGTH_MESSAGE(8))
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])/,
      'Must contain uppercase and lowercase letters'
    )
    .matches(/^(?=.*[0-9])/, 'Must contain at least one number')
    .matches(
      /[`!@#$%^&*()_+=[\]{};:"\\|,.<>/?~-]/,
      'Must contains one special character (ex. !@#$)'
    )
    .required('Password is required'),
  confirmPassword: Yup.string()
    .test('password-match', 'Password does not match', function (value) {
      return this.parent.password === value
    })
    .required('Please retype your password')
})

type RegisterFormProps = {
  onSuccess?: (email: string) => void
}

export const RegisterForm: FC<RegisterFormProps> = memo(
  ({ onSuccess }: RegisterFormProps) => {
    const { initialValues, handleSubmit, formResponse } = useRegister(onSuccess)

    return (
      <div className='mt-8 sm:mx-auto sm:w-full sm:max-w-md'>
        <div className='bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10'>
          <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ isSubmitting }) => (
              <Form noValidate className='space-y-6'>
                <div>
                  <FormField
                    name='email'
                    type='email'
                    label='Email address'
                    required
                  />
                  <FormFieldError name='email' />
                </div>

                <div>
                  <FormField
                    name='firstName'
                    type='text'
                    label='First name'
                    required
                  />
                  <FormFieldError name='firstName' />
                </div>

                <div>
                  <FormField
                    name='lastName'
                    type='text'
                    label='Last name'
                    required
                  />
                  <FormFieldError name='lastName' />
                </div>

                <div>
                  <FormField
                    name='password'
                    type='password'
                    label='Password'
                    required
                  />
                  <FormFieldError name='password' />
                </div>

                <div>
                  <FormField
                    name='confirmPassword'
                    type='password'
                    label='Confirm Password'
                    required
                  />
                  <FormFieldError name='confirmPassword' />
                </div>

                <div>
                  <Button
                    type='submit'
                    disabled={isSubmitting}
                    isLoading={isSubmitting}
                    className='w-full'
                  >
                    Sign Up
                  </Button>

                  {formResponse?.message && (
                    <div className='mt-2 text-sm text-red-600'>
                      {formResponse?.message}
                    </div>
                  )}
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    )
  }
)

RegisterForm.displayName = 'RegisterForm'

export default RegisterForm
