import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

import { PATHS } from '@/configs/misc'
import { Project } from '@/features/project'

export const useProjectCard = (project: Project) => {
  const navigate = useNavigate()

  const onClickProjectCard = useCallback(() => {
    navigate(`${PATHS.PROJECT.BASE}/${project.id}`)
  }, [project])

  return { onClickProjectCard }
}
