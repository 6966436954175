import { useQuery } from 'react-query'

import { APIs } from '@/configs/api'
import { Project } from '@/features/project'
import { axios } from '@/lib/axios'
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query'

export const getProjects = (): Promise<Project[]> => {
  return axios.get(APIs.PROJECT.GET_LIST, { withCredentials: true })
}

type QueryFnType = typeof getProjects

type UseProjectsOptions = {
  config?: QueryConfig<QueryFnType>
}

export const useProjects = ({ config }: UseProjectsOptions = {}) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: ['projects'],
    queryFn: () => getProjects()
  })
}
