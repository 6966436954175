import isHotkey from 'is-hotkey'
import TextareaAutosize from 'react-textarea-autosize'
import { Editable, Slate } from 'slate-react'

import {
  HOTKEYS,
  SlateAddLinkButton,
  SlateBlockButton,
  SlateMarkButton,
  SlateRemoveLinkButton,
  SlateToolbar,
  Tab,
  toggleMark,
  UploadButton
} from '@/components'
import { InterviewCardList } from '@/features/project'
import { useProjectIndexPage } from '@/hooks'

export const ProjectIndexPage = () => {
  const {
    projectData,
    activeTab,
    tabs,
    isUploadingInterview,
    onChangeTitle,
    onChangeDescription,
    onTabChange,
    enablePolling,
    disablePolling,
    editor,
    renderElement,
    renderLeaf,
    onQuestionContentChange,
    onClickUploadInterview
  } = useProjectIndexPage()

  return (
    <div className='flex flex-col'>
      <TextareaAutosize
        name='title'
        value={projectData.title}
        onInput={onChangeTitle}
        placeholder='Customer interviews (change to any title you want)'
        className='mt-6 w-full resize-none border-none py-4 px-2 font-syne text-4xl font-semibold text-black placeholder:text-black focus:outline-none focus:ring-0'
      />

      <TextareaAutosize
        name='description'
        value={projectData.description}
        onInput={onChangeDescription}
        placeholder='This is the project readme, a place to describe your project and provide an overview for newcomers. We’ve added some suggestions and resources below, but feel free to replace this text with anything you like.'
        className='w-full resize-none border-none p-2 font-inter text-base font-normal text-black placeholder:text-black focus:outline-none focus:ring-0'
      />

      <div className='my-6 px-2'>
        <UploadButton
          className='text-sm'
          callback={onClickUploadInterview}
          isLoading={isUploadingInterview}
          disabled={isUploadingInterview}
        >
          Upload an interview
        </UploadButton>
      </div>

      <div className='px-2'>
        <Tab
          className='mb-6'
          activeTab={activeTab}
          tabs={tabs}
          onTabChange={onTabChange}
        />
      </div>

      {activeTab?.slug === 'tab-research-questions' && (
        <div className='prose relative w-full max-w-screen-xl px-2'>
          <Slate
            editor={editor}
            value={projectData.questionsContent}
            onChange={onQuestionContentChange}
          >
            <SlateToolbar>
              <SlateMarkButton format='bold' icon='format_bold' />
              <SlateMarkButton format='italic' icon='format_italic' />
              <SlateMarkButton format='underline' icon='format_underlined' />
              <SlateBlockButton
                format='component.heading-one'
                icon='format_h1'
              />
              <SlateBlockButton
                format='component.heading-two'
                icon='format_h2'
              />
              <SlateBlockButton
                format='component.block-quote'
                icon='format_quote'
              />
              <SlateBlockButton
                format='component.bulleted-list'
                icon='format_list_bulleted'
              />
              <SlateBlockButton
                format='component.numbered-list'
                icon='format_list_numbered'
              />
              <SlateAddLinkButton />
              <SlateRemoveLinkButton />
              <SlateBlockButton format='left' icon='format_align_left' />
              <SlateBlockButton format='center' icon='format_align_center' />
              <SlateBlockButton format='right' icon='format_align_right' />
              <SlateBlockButton format='justify' icon='format_align_justify' />
              <SlateMarkButton format='code' icon='code' />
            </SlateToolbar>

            <Editable
              renderElement={renderElement}
              renderLeaf={renderLeaf}
              placeholder='Write something…'
              spellCheck
              autoFocus
              onKeyDown={event => {
                let hotkey: keyof typeof HOTKEYS
                for (hotkey in HOTKEYS) {
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  if (isHotkey(hotkey, event as any)) {
                    event?.preventDefault()
                    const mark = HOTKEYS[hotkey]
                    toggleMark(editor, mark as never)
                  }
                }
              }}
            />
          </Slate>
        </div>
      )}

      {activeTab?.slug === 'tab-interviews' && (
        <div>
          <InterviewCardList
            interviews={projectData.interviews}
            enablePolling={enablePolling}
            disablePolling={disablePolling}
          />
        </div>
      )}
    </div>
  )
}

ProjectIndexPage.displayName = 'ProjectIndexPage'

export default ProjectIndexPage
