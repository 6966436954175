import { Suspense } from 'react'
import { Navigate, Outlet } from 'react-router-dom'

import { AppLayout, Spinner } from '@/components'
import { lazyImport } from '@/utils'

const { ProjectRoutes } = lazyImport(
  () => import('@/features/project'),
  'ProjectRoutes'
)

const { DashboardPage } = lazyImport(
  () => import('@/features/project'),
  'DashboardPage'
)

const { SettingsPage } = lazyImport(
  () => import('@/features/project'),
  'SettingsPage'
)

const { InterviewRoutes } = lazyImport(
  () => import('@/features/interview'),
  'InterviewRoutes'
)

const App = () => {
  return (
    <AppLayout>
      <Suspense
        fallback={
          <div className='flex h-full w-full items-center justify-center'>
            <Spinner size='lg' />
          </div>
        }
      >
        <Outlet />
      </Suspense>
    </AppLayout>
  )
}

export const protectedRoutes = [
  {
    path: '/',
    element: <App />,
    children: [
      { path: '', element: <DashboardPage /> },
      { path: 'settings', element: <SettingsPage /> },
      { path: 'project/*', element: <ProjectRoutes /> },
      { path: 'interview/*', element: <InterviewRoutes /> },
      { path: '*', element: <Navigate to='.' /> }
    ]
  }
]
