import './AuthLayout.scss'

import { FC, memo } from 'react'

type AuthLayoutProps = {
  children: React.ReactNode
}

export const AuthLayout: FC<AuthLayoutProps> = memo(
  ({ children }: AuthLayoutProps) => {
    return (
      <div className='relative flex min-h-screen flex-col justify-center'>
        <div className='auth-layout-wrapper absolute z-[-1] h-full w-full blur-2xl' />
        {children}
      </div>
    )
  }
)

AuthLayout.displayName = 'AuthLayout'

export default AuthLayout
