import { Form, Formik } from 'formik'
import { FC, memo } from 'react'
import * as Yup from 'yup'

import { Button, FormField, FormFieldError } from '@/components'
import { useRegisterVerification } from '@/hooks'

const validationSchema = Yup.object().shape({
  code: Yup.string().required('Please fill in verification code')
})

type RegisterFormProps = {
  userEmail: string
  onSuccess?: () => void
}

export const RegisterVerificationForm: FC<RegisterFormProps> = memo(
  ({ userEmail, onSuccess }: RegisterFormProps) => {
    const { initialValues, handleSubmit, formResponse } =
      useRegisterVerification(userEmail, onSuccess)

    return (
      <div className='mt-8 sm:mx-auto sm:w-full sm:max-w-md'>
        <div className='bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10'>
          <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ isSubmitting }) => (
              <Form noValidate className='space-y-6'>
                <div>
                  <div className='mb-3'>
                    Please check your inbox to get your verification code
                  </div>
                  <FormField name='code' type='text' required />
                  <FormFieldError name='code' />
                </div>

                <div>
                  <Button
                    type='submit'
                    disabled={isSubmitting}
                    isLoading={isSubmitting}
                    className='w-full'
                  >
                    Verify
                  </Button>

                  {formResponse?.message && (
                    <div className='mt-2 text-sm text-red-600'>
                      {formResponse?.message}
                    </div>
                  )}
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    )
  }
)

RegisterVerificationForm.displayName = 'RegisterVerificationForm'

export default RegisterVerificationForm
