/* eslint-disable @typescript-eslint/no-explicit-any */
import isUrl from 'is-url'
import { BaseEditor } from 'slate'
import { HistoryEditor } from 'slate-history'
import { ReactEditor } from 'slate-react'

import SlateBulletedList from './SlateBulletedList'
import SlateLink from './SlateLink'
import SlateList from './SlateList'
import SlateNumberedList from './SlateNumberedList'
import SlateParagraph from './SlateParagraph'
import SlateQuote from './SlateQuote'
import SlateSubtitle from './SlateSubtitle'
import SlateTitle from './SlateTitle'
import SlateTranscriptQa from './SlateTranscriptQa'
import { wrapLink } from './utils'

export const HOTKEYS = {
  'mod+b': 'bold',
  'mod+i': 'italic',
  'mod+u': 'underline',
  'mod+`': 'code'
}

export const withInlines = (
  editor: BaseEditor & ReactEditor & HistoryEditor
) => {
  const { insertData, insertText, isInline, isVoid } = editor

  editor.isInline = element =>
    ['component.link', 'component.button'].includes(element.type) ||
    isInline(element)

  editor.insertText = text => {
    if (text && isUrl(text)) {
      wrapLink(editor, text)
    } else {
      insertText(text)
    }
  }

  editor.insertData = data => {
    const text = data.getData('text/plain')

    if (text && isUrl(text)) {
      wrapLink(editor, text)
    } else {
      insertData(data)
    }
  }

  editor.isVoid = element =>
    ['component.transcript.qa'].includes(element.type) ? true : isVoid(element)

  return editor
}

export const SlateElement = (props: any) => {
  const { attributes, children, element } = props
  const style = { textAlign: element.align }
  switch (element.type) {
    case 'component.block-quote':
      return (
        <SlateQuote style={style} {...attributes}>
          {children}
        </SlateQuote>
      )
    case 'component.bulleted-list':
      return (
        <SlateBulletedList style={style} {...attributes}>
          {children}
        </SlateBulletedList>
      )
    case 'component.heading-one':
      return (
        <SlateTitle style={style} {...attributes}>
          {children}
        </SlateTitle>
      )
    case 'component.heading-two':
      return (
        <SlateSubtitle style={style} {...attributes}>
          {children}
        </SlateSubtitle>
      )
    case 'component.list-item':
      return (
        <SlateList style={style} {...attributes}>
          {children}
        </SlateList>
      )
    case 'component.numbered-list':
      return (
        <SlateNumberedList style={style} {...attributes}>
          {children}
        </SlateNumberedList>
      )
    case 'component.link':
      return <SlateLink {...props} />
    case 'component.transcript.qa':
      return <SlateTranscriptQa {...props} />
    default:
      return (
        <SlateParagraph style={style} {...attributes}>
          {children}
        </SlateParagraph>
      )
  }
}
