import { FC, memo } from 'react'

export interface IAppIconProps {
  width?: number
  height?: number
  className?: string
  color?: string
}

export const AppIcon: FC<IAppIconProps> = memo(
  ({
    width = 24,
    height = 24,
    className = '',
    color = '#4338CA'
  }: IAppIconProps) => {
    return (
      <svg
        width={width}
        height={height}
        viewBox='0 0 21 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        className={className}
      >
        <path
          d='M0.5 15.9688V7.34375H7.71875V12.2188C7.71875 14.3754 8.47703 15.9737 10.0903 16.8461L10.0973 16.8499L10.1045 16.8535C11.0108 17.3066 12.1635 17.5156 13.5312 17.5156H13.7344C15.2253 17.5156 16.5582 17.1915 17.7173 16.5276L17.7173 16.5277L17.7231 16.5242C18.7172 15.9368 19.5439 15.0998 20.2065 14.0287L20.2184 14.0094L20.2285 13.9892C20.2507 13.9448 20.2669 13.9159 20.2774 13.8988C20.2776 13.8985 20.2777 13.8982 20.2779 13.898L20.3172 13.8587L20.3535 13.7861L20.4531 13.5868V15.9688C20.4531 18.0631 19.757 19.6417 18.3989 20.7719L18.3961 20.7743C16.8437 22.0856 14.421 22.8288 11.04 22.9219H10.5625C7.01245 22.9219 4.43618 22.2067 2.74957 20.8593L2.74964 20.8593L2.74161 20.8531C2.00848 20.2914 1.43916 19.5668 1.03381 18.6692L1.03292 18.6673C0.682153 17.8994 0.5 17.0035 0.5 15.9688ZM13.5938 16.2344C12.3018 16.2344 11.3521 16.0465 10.7023 15.7141C9.68246 15.1581 9.10425 14.1986 9.01562 12.7197V12.6719V12.6103L9.0007 12.5506L9.00067 12.5505C9.00027 12.5485 8.99524 12.5231 8.991 12.4617C8.98685 12.4014 8.98438 12.3212 8.98438 12.2188V11.6049C9.51568 11.9155 10.1955 12.0469 10.9688 12.0469H11.0469C12.1075 12.0469 12.9885 11.7741 13.5402 11.1016C14.0244 10.5309 14.2031 9.6196 14.2031 8.51562V0.5H20.4531V8.27036C20.3608 10.3752 19.906 12.0525 19.1207 13.3322L19.1185 13.3359C17.9559 15.2671 16.1884 16.2344 13.7344 16.2344H13.5938ZM7.71812 6.02129C7.71774 6.02215 7.71431 6.02986 7.70186 6.04371C7.68436 6.05763 7.66915 6.0625 7.64062 6.0625H0.578125C0.549598 6.0625 0.534387 6.05763 0.516886 6.04371C0.504442 6.02986 0.501005 6.02215 0.500626 6.02129L0.500328 6.02054L0.500309 6.02046L0.500159 6.01938L0.5 6.01562V0.5H7.71875V6.01562L7.71859 6.01938L7.71844 6.02046L7.71842 6.02054L7.71812 6.02129Z'
          fill={color}
        />
      </svg>
    )
  }
)

AppIcon.displayName = 'AppIcon'

export default AppIcon
