/* eslint-disable @typescript-eslint/no-explicit-any */
import isHotkey from 'is-hotkey'
import {
  ChangeEvent,
  KeyboardEvent,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react'
import { Descendant } from 'slate'
import { useDebounce, useUpdateEffect } from 'usehooks-ts'

import { HOTKEYS, toggleMark } from '@/components'
import { UpdateSpeakerDTO, useUpdateSpeaker } from '@/features/speaker'
import { Transcript } from '@/features/transcript'
import { UpdateTranscriptDTO, useUpdateTranscript } from '@/features/transcript'
import { useSlateEditor } from '@/hooks'

export const useInterviewTranscript = (transcript: Transcript) => {
  const [transcriptData, setTranscriptData] = useState<Transcript>(transcript)
  const [transcriptContent, setTranscriptContent] = useState<Descendant[]>([])
  const [commentContent, setCommentContent] = useState<Descendant[]>([])
  const debouncedData = useDebounce(transcriptData, 500)

  const {
    editor: transcriptEditor,
    renderElement: transcriptRenderElement,
    renderLeaf: transcriptRenderLeaf
  } = useSlateEditor()

  const {
    editor: commentEditor,
    renderElement: commentRenderElement,
    renderLeaf: commentRenderLeaf
  } = useSlateEditor()

  const { mutateAsync: updateTranscript } = useUpdateTranscript()
  const { mutateAsync: updateSpeaker } = useUpdateSpeaker()

  const timestamp = useMemo(
    () =>
      transcript?.startTime
        ? new Date(transcript.startTime * 1000).toISOString().slice(11, 19)
        : '00:00:00',
    [transcript?.startTime]
  )

  const onTranscriptEditorKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
    let hotkey: keyof typeof HOTKEYS
    for (hotkey in HOTKEYS) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      if (isHotkey(hotkey, event as any)) {
        event?.preventDefault()
        const mark = HOTKEYS[hotkey]
        toggleMark(transcriptEditor, mark as never)
      }
    }
  }

  const onCommentEditorKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
    let hotkey: keyof typeof HOTKEYS
    for (hotkey in HOTKEYS) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      if (isHotkey(hotkey, event as any)) {
        event?.preventDefault()
        const mark = HOTKEYS[hotkey]
        toggleMark(commentEditor, mark as never)
      }
    }
  }

  const onTranscriptChange = (value: Descendant[]) => {
    setTranscriptData({
      ...transcriptData,
      transcriptContent: JSON.stringify(value)
    })
  }

  const onCommentChange = (value: Descendant[]) => {
    setTranscriptData({
      ...transcriptData,
      commentContent: JSON.stringify(value)
    })
  }

  const onChangeSpeakerTitle = (event: ChangeEvent<HTMLInputElement>) => {
    setTranscriptData({
      ...transcriptData,
      speaker: {
        ...transcriptData.speaker,
        title: event.target.value
      }
    })
  }

  const saveTranscriptChanges = useCallback(
    async (data: Transcript) => {
      const request: UpdateTranscriptDTO = {
        id: data.id as number,
        transcriptContent: data.transcriptContent,
        commentContent: data.commentContent
      }
      await updateTranscript(request)
    },
    [updateTranscript]
  )

  const saveSpeakerChanges = useCallback(
    async (data: Transcript) => {
      const request: UpdateSpeakerDTO = {
        id: data?.speaker.id,
        title: data?.speaker.title
      }
      await updateSpeaker(request)
    },
    [updateSpeaker]
  )

  useEffect(() => {
    let newTranscriptContent: Descendant[] = []
    try {
      newTranscriptContent = JSON.parse(
        transcript.transcriptContent
      ) as Descendant[]
    } catch (error) {
      console.error(`Error while parsing transcript content: ${error}`)
      newTranscriptContent = []
    }
    setTranscriptContent(newTranscriptContent)
    transcriptEditor.children = newTranscriptContent

    let newCommentContent: Descendant[] = []
    try {
      newCommentContent = JSON.parse(transcript.commentContent) as Descendant[]
    } catch (error) {
      console.error(`Error while parsing comment content: ${error}`)
      newCommentContent = []
    }
    setCommentContent(newCommentContent)
    commentEditor.children = newCommentContent
  }, [
    transcript?.transcriptContent,
    transcript?.commentContent,
    transcriptEditor,
    commentEditor,
    setTranscriptContent,
    setCommentContent
  ])

  useUpdateEffect(() => {
    if (debouncedData) {
      saveTranscriptChanges(debouncedData)
    }
  }, [
    debouncedData?.transcriptContent,
    debouncedData?.commentContent,
    saveTranscriptChanges
  ])

  useUpdateEffect(() => {
    if (debouncedData) {
      saveSpeakerChanges(debouncedData)
    }
  }, [debouncedData?.speaker?.title, saveSpeakerChanges])

  return {
    transcriptData,
    transcriptContent,
    commentContent,
    timestamp,
    transcriptEditor,
    commentEditor,
    transcriptRenderElement,
    transcriptRenderLeaf,
    commentRenderElement,
    commentRenderLeaf,
    onTranscriptEditorKeyDown,
    onTranscriptChange,
    onCommentEditorKeyDown,
    onCommentChange,
    onChangeSpeakerTitle
  }
}
