import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

import { PATHS } from '@/configs/misc'
import { useCreateProject, useProjects } from '@/features/project'

export const useDashboardPage = () => {
  const navigate = useNavigate()

  const { data: projects, isLoading: isProjectsLoading } = useProjects()

  const { mutateAsync: createProject, isLoading: isCreatingProject } =
    useCreateProject()

  const onClickNewProject = useCallback(async () => {
    const newProject = await createProject(undefined)
    navigate(`${PATHS.PROJECT.BASE}/${newProject.id}`)
  }, [createProject])

  return {
    projects: projects ?? [],
    isProjectsLoading,
    isCreatingProject,
    onClickNewProject
  }
}
