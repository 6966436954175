import { AppIcon } from '@/components'
import { PATHS } from '@/configs/misc'
import { AuthLayout, ForgotPasswordForm } from '@/features/auth'

export const ForgotPasswordPage = () => {
  return (
    <AuthLayout>
      <div className='flex flex-col items-center justify-center sm:mx-auto sm:w-full sm:max-w-md'>
        <AppIcon width={40} height={40} />

        <h2 className='mt-6 text-center font-inter text-3xl font-bold text-gray-900'>
          Forgot your password?
        </h2>

        <p className='mt-2 text-center text-sm font-normal text-gray-900'>
          Enter your email and we will send you a reset password link
        </p>

        <p className='text-center text-sm font-normal text-gray-900'>
          Remembered your password?
          <a
            href={PATHS.AUTH.LOGIN}
            className='pl-1 font-medium text-indigo-600 hover:text-indigo-500'
          >
            Login
          </a>
        </p>
      </div>
      <ForgotPasswordForm />
    </AuthLayout>
  )
}

ForgotPasswordPage.displayName = 'ForgotPasswordPage'

export default ForgotPasswordPage
