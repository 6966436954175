import { FormikHelpers } from 'formik'
import { useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'

import { DEFAULT_ERROR_MESSAGE } from '@/configs/message'
import { resetPassword, ResetPasswordFormValues } from '@/features/auth'
import { FormResponse } from '@/types'

const initialValues: ResetPasswordFormValues = {
  password: '',
  confirmPassword: ''
}

export const useResetPassword = (onSuccess?: () => void) => {
  const { search } = useLocation()

  const token = useMemo(() => {
    const params = new URLSearchParams(search)
    return params.get('token')
  }, [search])

  const [formResponse, setFormResponse] = useState<FormResponse>({
    isSuccess: false
  })

  const handleSubmit = async (
    values: ResetPasswordFormValues,
    actions: FormikHelpers<ResetPasswordFormValues>
  ) => {
    try {
      actions.setSubmitting(true)
      setFormResponse({
        isSuccess: false
      })

      if (token) {
        await resetPassword({
          token,
          password: values.password
        })

        setFormResponse({
          isSuccess: true,
          message:
            'Password is reset successfully. Redirecting to login page automatically...'
        })

        onSuccess?.()
      } else {
        throw Error('No token provided')
      }
    } catch (error) {
      const errorMessage = (error as string) ?? DEFAULT_ERROR_MESSAGE
      setFormResponse({
        isSuccess: false,
        message: errorMessage
      })
    } finally {
      actions.setSubmitting(false)
    }
  }

  return { initialValues, handleSubmit, formResponse }
}
