import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDebounce, useUpdateEffect } from 'usehooks-ts'

import { PATHS } from '@/configs/misc'
import {
  Interview,
  UpdateInterviewDTO,
  useUnpakInterview,
  useUpdateInterview
} from '@/features/interview'
import { isInterviewProcessing, isInterviewUnpakd } from '@/utils'

export const useInterviewCard = (
  interview: Interview,
  enablePolling: () => void,
  disablePolling: () => void
) => {
  const navigate = useNavigate()

  const { mutateAsync: unpakdInterview } = useUnpakInterview({
    interviewId: interview.id as number
  })

  const { mutateAsync: updateInterview } = useUpdateInterview()

  const [interviewData, setInterviewData] = useState<Interview>(interview)
  const [isProcessing, setIsProcessing] = useState(
    isInterviewProcessing(interview.status)
  )

  const debouncedData = useDebounce(interviewData, 300)

  const isUnpakd = useMemo(
    () => isInterviewUnpakd(interviewData.status),
    [interviewData.status]
  )

  const saveChanges = useCallback(
    async (data: Interview) => {
      const request: UpdateInterviewDTO = {
        id: data.id as number,
        title: data.title,
        description: data.description
      }
      const updatedInterview = await updateInterview(request)
      setIsProcessing(isInterviewProcessing(updatedInterview.status))
      enablePolling()
    },
    [updateInterview, enablePolling, setIsProcessing]
  )

  const onClickInterviewCard = useCallback(async () => {
    if (isUnpakd) {
      navigate(`${PATHS.INTERVIEW.BASE}/${interview.id}`)
    } else {
      if (isProcessing) {
        console.log('Already unpaking the interview...')
      } else {
        try {
          setIsProcessing(true)
          const unpakdedInterview = await unpakdInterview({
            interviewId: interview.id as number
          })
          setInterviewData(unpakdedInterview)
          setIsProcessing(isInterviewProcessing(unpakdedInterview.status))
        } catch (error) {
          console.error(error)
          setIsProcessing(false)
        }
      }
    }
  }, [
    interview.id,
    isUnpakd,
    isProcessing,
    unpakdInterview,
    navigate,
    setInterviewData,
    setIsProcessing
  ])

  const onClickRepakInterview = useCallback(async () => {
    if (isUnpakd) {
      try {
        setIsProcessing(true)
        const unpakdedInterview = await unpakdInterview({
          interviewId: interview.id as number
        })
        setInterviewData(unpakdedInterview)
        setIsProcessing(isInterviewProcessing(unpakdedInterview.status))
      } catch (error) {
        console.error(error)
        setIsProcessing(false)
      }
    }
  }, [isUnpakd, unpakdInterview, setInterviewData, setIsProcessing])

  const onChangeInterviewTitle = (event: ChangeEvent<HTMLInputElement>) => {
    disablePolling()
    setInterviewData({
      ...interviewData,
      title: event.target.value as string
    })
  }

  const onChangeInterviewDescription = (
    event: ChangeEvent<HTMLTextAreaElement>
  ) => {
    disablePolling()
    setInterviewData({
      ...interviewData,
      description: event.target.value as string
    })
  }

  useEffect(() => {
    if (interview) {
      setInterviewData(interview)
      setIsProcessing(isInterviewProcessing(interview.status))
    }
  }, [interview, setInterviewData, setIsProcessing])

  useUpdateEffect(() => {
    if (debouncedData.title && debouncedData.description) {
      saveChanges(debouncedData)
    }
  }, [debouncedData.title, debouncedData.description, saveChanges])

  return {
    interviewData,
    isUnpakd,
    isProcessing,
    onClickInterviewCard,
    onClickRepakInterview,
    onChangeInterviewTitle,
    onChangeInterviewDescription
  }
}
