export enum InterviewSpeakerTypeEnum {
  Interviewer = 'interviewer',
  Interviewee = 'interviewee'
}

export type Speaker = {
  id: number
  title: string
  interviewSpeakerId: string
  speakerType: InterviewSpeakerTypeEnum
}
