import { AppIcon } from '@/components'
import { PATHS } from '@/configs/misc'
import {
  AuthLayout,
  RegisterForm,
  RegisterVerificationForm
} from '@/features/auth'
import { useRegisterPage } from '@/hooks'

export const RegisterPage = () => {
  const {
    step,
    userEmail,
    onRegisterationRequestSuccess,
    onRegisterVerificationSuccess
  } = useRegisterPage()

  return (
    <AuthLayout>
      <div className='flex flex-col items-center justify-center sm:mx-auto sm:w-full sm:max-w-md'>
        <AppIcon width={40} height={40} />

        <h2 className='mt-6 text-center font-inter text-3xl font-bold text-gray-900'>
          Register your account
        </h2>

        <p className='mt-2 text-center text-sm font-normal text-gray-900'>
          Or{' '}
          <a
            href={PATHS.AUTH.LOGIN}
            className='font-medium text-indigo-600 hover:text-indigo-500'
          >
            log in your account
          </a>
        </p>
      </div>

      {step === 'registration-request' && (
        <RegisterForm onSuccess={onRegisterationRequestSuccess} />
      )}
      {step === 'registration-verification' && (
        <RegisterVerificationForm
          userEmail={userEmail}
          onSuccess={onRegisterVerificationSuccess}
        />
      )}
    </AuthLayout>
  )
}

RegisterPage.displayName = 'RegisterPage'

export default RegisterPage
