/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMutation } from 'react-query'

import { APIs } from '@/configs/api'
import { Interview } from '@/features/interview'
import { axios } from '@/lib/axios'
import { MutationConfig, queryClient } from '@/lib/react-query'

export type CreateInterviewRequestDto = {
  projectId: number
  source: string
  sourceFormat: string
}

export const createInterview = (
  request: CreateInterviewRequestDto
): Promise<Interview> =>
  axios.post(APIs.INTERVIEW.CREATE, request, { withCredentials: true })

type UseCreateInterviewOptions = {
  config?: MutationConfig<typeof createInterview>
}

export const useCreateInterview = ({
  config
}: UseCreateInterviewOptions = {}) => {
  return useMutation({
    onError: (_, __, context: any) => {
      if (context?.previousInterviews) {
        queryClient.setQueryData('interviews', context.previousInterviews)
      }
    },
    onSuccess: (_, request) => {
      queryClient.invalidateQueries('interviews')
      queryClient.refetchQueries(['project', request.projectId])
    },
    ...config,
    mutationFn: createInterview
  })
}
