import { forwardRef, Ref } from 'react'

import { OrNull } from '@/types'

export const SlateParagraph = forwardRef(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (props: any, ref: Ref<OrNull<HTMLParagraphElement>>) => {
    return <p {...props} ref={ref} />
  }
)

SlateParagraph.displayName = 'SlateParagraph'

export default SlateParagraph
