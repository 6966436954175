import { useNavigate } from 'react-router-dom'

import { AppIcon } from '@/components'
import { PATHS } from '@/configs/misc'
import { AuthLayout, LoginForm } from '@/features/auth'

export const LoginPage = () => {
  const navigate = useNavigate()

  return (
    <AuthLayout>
      <div className='flex flex-col items-center justify-center sm:mx-auto sm:w-full sm:max-w-md'>
        <AppIcon width={40} height={40} />

        <h2 className='mt-6 text-center font-inter text-3xl font-bold text-gray-900'>
          Log in to your account
        </h2>

        <p className='mt-2 text-center text-sm font-normal text-gray-900'>
          Or{' '}
          <a
            href={PATHS.AUTH.REGISTER}
            className='font-medium text-indigo-600 hover:text-indigo-500'
          >
            register your account
          </a>
        </p>
      </div>
      <LoginForm onSuccess={() => navigate(PATHS.BASE)} />
    </AuthLayout>
  )
}

LoginPage.displayName = 'LoginPage'

export default LoginPage
