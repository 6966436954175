import { FormikHelpers } from 'formik'
import { useState } from 'react'

import { DEFAULT_ERROR_MESSAGE } from '@/configs/message'
import { forgotPassword, ForgotPasswordFormValues } from '@/features/auth'
import { FormResponse } from '@/types'

const initialValues: ForgotPasswordFormValues = {
  email: ''
}

export const useForgotPassword = () => {
  const [formResponse, setFormResponse] = useState<FormResponse>({
    isSuccess: false
  })

  const handleSubmit = async (
    values: ForgotPasswordFormValues,
    actions: FormikHelpers<ForgotPasswordFormValues>
  ) => {
    actions.setSubmitting(true)
    setFormResponse({
      isSuccess: false
    })
    try {
      await forgotPassword({ email: values.email })
      setFormResponse({
        isSuccess: true,
        message:
          'Your request sent successfully and please check your inbox to continue.'
      })
    } catch (error) {
      const errorMessage = (error as string) ?? DEFAULT_ERROR_MESSAGE
      setFormResponse({
        isSuccess: false,
        message: errorMessage
      })
    } finally {
      actions.setSubmitting(false)
    }
  }

  return { initialValues, handleSubmit, formResponse }
}
