export enum UserStatusEnum {
  Pending = 'pending',
  Active = 'active',
  Deleted = 'deleted'
}

export type User = {
  userId: string
  email: string
  emailVerified: string
  firstName: string
  lastName: string
  avatar?: string
  status: UserStatusEnum
  roles: RoleEnum[]
}

export enum RoleEnum {
  SuperAdmin = 'super-admin',
  User = 'user'
}

export type LoginFormValues = {
  email: string
  password: string
  rememberMe: boolean
}

export type RegisterationStep =
  | 'registration-request'
  | 'registration-verification'

export type RegisterFormValues = {
  firstName: string
  lastName: string
  email: string
  password: string
  confirmPassword: string
}

export type RegisterVerificationFormValues = {
  code: string
}

export type ForgotPasswordFormValues = {
  email: string
}

export type ResetPasswordFormValues = {
  password: string
  confirmPassword: string
}
