/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMutation } from 'react-query'

import { APIs } from '@/configs/api'
import { Project } from '@/features/project'
import { axios } from '@/lib/axios'
import { MutationConfig, queryClient } from '@/lib/react-query'

export const createProject = (): Promise<Project> =>
  axios.post(APIs.PROJECT.CREATE, null, { withCredentials: true })

type UseCreateProjectOptions = {
  config?: MutationConfig<typeof createProject>
}

export const useCreateProject = ({ config }: UseCreateProjectOptions = {}) => {
  return useMutation({
    onError: (_, __, context: any) => {
      if (context?.previousProjects) {
        queryClient.setQueryData('projects', context.previousProjects)
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries('projects')
    },
    ...config,
    mutationFn: createProject
  })
}
