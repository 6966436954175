import cover from '@/assets/images/cover.png'
import { PageTitle } from '@/components'
import { ProjectCardList } from '@/features/project'
import { useDashboardPage } from '@/hooks'

export const DashboardPage = () => {
  const { projects, onClickNewProject } = useDashboardPage()

  return (
    <div className='flex flex-col'>
      <img src={cover} className='h-[256px] w-full rounded-2xl object-cover' />
      <div className='flex w-full flex-col'>
        <PageTitle className='my-6' title='A home for insights' />
        <span className='mb-8 font-inter text-base font-normal'>
          Welcome to Unpakd! You can upload video or sound files and let our AI
          help you unpack insights from your interviews.
        </span>

        <ProjectCardList
          projects={projects}
          onClickNewProject={onClickNewProject}
        />
      </div>
    </div>
  )
}

DashboardPage.displayName = 'DashboardPage'

export default DashboardPage
