import { APIs } from '@/configs/api'
import { axios } from '@/lib/axios'

export type RegisterCredentialsDTO = {
  email: string
  firstName: string
  lastName: string
  password: string
}

export type RegisterVerificationDTO = {
  email: string
  code: string
}

export const registerWithEmailAndPassword = (data: RegisterCredentialsDTO) =>
  axios.post(APIs.AUTH.REGISTER, data)

export const verifyRegistrationCode = (data: RegisterVerificationDTO) =>
  axios.post(APIs.AUTH.VERIFY_EMAIL, data)
