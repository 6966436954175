import { useQuery } from 'react-query'

import { APIs } from '@/configs/api'
import { Interview } from '@/features/interview'
import { axios } from '@/lib/axios'
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query'

export const getInterviews = (projectId: number): Promise<Interview[]> => {
  return axios.get(`${APIs.INTERVIEW.GET_LIST}/${projectId}`, {
    withCredentials: true
  })
}

type QueryFnType = typeof getInterviews

type UseInterviewsOptions = {
  projectId: number
  config?: QueryConfig<QueryFnType>
}

export const useInterviews = ({ projectId, config }: UseInterviewsOptions) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: ['interviews'],
    queryFn: () => getInterviews(projectId)
  })
}
