import { APIs } from '@/configs/api'
import { axios } from '@/lib/axios'

export type ResetPasswordDTO = {
  token: string
  password: string
}

export const resetPassword = (data: ResetPasswordDTO) =>
  axios.post(APIs.AUTH.RESET_PASSWORD, data)
