import { Navigate, Route, Routes } from 'react-router-dom'

import { ProjectIndexPage, ProjectSettingsPage } from '@/features/project'

export const ProjectRoutes = () => {
  return (
    <Routes>
      <Route path=':projectId' element={<ProjectIndexPage />} />
      <Route path=':projectId/settings' element={<ProjectSettingsPage />} />
      <Route path='*' element={<Navigate to='/' />} />
    </Routes>
  )
}
