import { initReactQueryAuth } from 'react-query-auth'

import { Spinner } from '@/components'
import {
  getUser,
  LoginCredentialsDTO,
  loginWithEmailAndPassword,
  logout,
  RegisterCredentialsDTO,
  registerWithEmailAndPassword,
  User
} from '@/features/auth'
import { storage } from '@/utils'

async function loadUser() {
  if (storage.getToken()) {
    try {
      const data = await getUser()
      return data
    } catch (error) {
      storage.clearToken()
      await logout()
      return null
    }
  }
  return null
}

async function loginFn(data: LoginCredentialsDTO) {
  const user = await loginWithEmailAndPassword(data)
  storage.setToken(JSON.stringify(user))
  return user
}

async function registerFn(data: RegisterCredentialsDTO) {
  await registerWithEmailAndPassword(data)
  return null
}

async function logoutFn() {
  storage.clearToken()
  await logout()
}

const authConfig = {
  loadUser,
  loginFn,
  registerFn,
  logoutFn,
  LoaderComponent() {
    return (
      <div className='flex h-screen w-screen items-center justify-center'>
        <Spinner size='lg' />
      </div>
    )
  }
}

export const { AuthProvider, useAuth } = initReactQueryAuth<
  User | null,
  unknown,
  LoginCredentialsDTO,
  RegisterCredentialsDTO
>(authConfig)
